import { V2FormTemplate } from '@terragotech/form-renderer';

export const sample: V2FormTemplate = {
  components: {
    Name: {
      type: 'textInput',
      required: false,
      label: 'Name',
      repeats: { min: 1, max: 1 },
      placeholder: 'Enter text',
    },
    'Show Attributes': {
      type: 'radio',
      required: false,
      label: 'Show Attributes',
      repeats: { min: 1, max: 1 },
      placeholder: 'placeholder',
      options: {
        type: 'SimpleOption',
        items: [
          { value: 'Hide', label: 'Hide' },
          { value: 'Show', label: 'Show' },
        ],
      },
    },
    'Existing Attributes': {
      type: 'group',
      conditionalMap: {
        nodes: {
          FORM: { type: 'FORM', name: 'Form' },
          newNode1: {
            name: 'Is Install',
            type: 'STRING-EQUALS',
            inputs: {
              firstSource: { sourcePath: '$.Select Action', sourceObject: 'FORM' },
              secondSource: { sourcePath: '$.newNode2_output', sourceObject: 'newNode2' },
            },
            config: {},
          },
          newNode2: {
            name: 'Is Install',
            type: 'STRING-CONCAT',
            inputs: { strings: ['Replace Node'] },
            config: {},
          },
          FORM_CONDITIONAL: {
            type: 'FORM-CONDITIONAL',
            inputs: { isVisible: { sourceObject: 'newNode1', sourcePath: '$.newNode1_output' } },
          },
        },
        outputDefinition: { outputNode: 'FORM_CONDITIONAL' },
      },
      label: 'Existing Attributes',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          idOnController: {
            type: 'textInput',
            required: false,
            label: 'idOnController',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Client Number': {
            type: 'textInput',
            required: false,
            label: 'Client Number',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Site Name': {
            type: 'textInput',
            required: false,
            label: 'Site Name',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Feature ID': {
            type: 'textInput',
            required: false,
            label: 'Feature ID',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Central Asset ID': {
            type: 'textInput',
            required: false,
            label: 'Central Asset ID',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Site Code': {
            type: 'textInput',
            required: false,
            label: 'Site Code',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'GeoZone Path': {
            type: 'textInput',
            required: false,
            label: 'GeoZone Path',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Feature Location': {
            type: 'textArea',
            required: false,
            label: 'Feature Location',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lamp Type': {
            type: 'textInput',
            required: false,
            label: 'Lamp Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lamp Wattage': {
            type: 'textInput',
            required: false,
            label: 'Lamp Wattage',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lamp Change': {
            type: 'textInput',
            required: false,
            label: 'Lamp Change',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Gear Type': {
            type: 'textInput',
            required: false,
            label: 'Gear Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Column Type': {
            type: 'textInput',
            required: false,
            label: 'Column Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Column Material': {
            type: 'textInput',
            required: false,
            label: 'Column Material',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Mounting Height': {
            type: 'textInput',
            required: false,
            label: 'Mounting Height',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Column Fixing': {
            type: 'textInput',
            required: false,
            label: 'Column Fixing',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lantern MFG': {
            type: 'textInput',
            required: false,
            label: 'Lantern MFG',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lantern Install Date': {
            type: 'textInput',
            required: false,
            label: 'Lantern Install Date',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Lantern Type': {
            type: 'textInput',
            required: false,
            label: 'Lantern Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Light Class': {
            type: 'textInput',
            required: false,
            label: 'Light Class',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Bracket Type': {
            type: 'textInput',
            required: false,
            label: 'Bracket Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
        },
        order: [
          'idOnController',
          'Client Number',
          'Site Name',
          'Feature ID',
          'Central Asset ID',
          'Site Code',
          'GeoZone Path',
          'Feature Location',
          'Lamp Type',
          'Lamp Wattage',
          'Lamp Change',
          'Gear Type',
          'Column Type',
          'Column Material',
          'Mounting Height',
          'Column Fixing',
          'Lantern MFG',
          'Lantern Install Date',
          'Lantern Type',
          'Light Class',
          'Bracket Type',
        ],
      },
    },
    'Select Action': {
      type: 'radio',
      required: false,
      label: 'Select Action',
      repeats: { min: 1, max: 1 },
      placeholder: 'placeholder',
      options: {
        type: 'SimpleOption',
        items: [
          { value: 'Install Node', label: 'Install Node' },
          { value: 'Replace Node', label: 'Replace Node' },
          { value: 'Remove Node', label: 'Remove Node' },
        ],
      },
    },
    'Install Node': {
      type: 'group',
      label: 'Install Node',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          'Scan Node': {
            type: 'barcode',
            required: true,
            label: 'Scan Node',
            repeats: { min: 1, max: 1 },
          },
          'Install Date': {
            type: 'date',
            required: true,
            label: 'Install Date',
            repeats: { min: 1, max: 1 },
            placeholder: 'Select date',
          },
        },
        order: ['Scan Node', 'Install Date'],
      },
    },
    'Replace Node': {
      type: 'group',
      label: 'Replace Node',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          'Existing Node': {
            type: 'barcode',
            required: true,
            label: 'Existing Node',
            repeats: { min: 1, max: 1 },
          },
          'Replacement Date': {
            type: 'date',
            required: true,
            label: 'Replacement Date',
            repeats: { min: 1, max: 1 },
            placeholder: 'Select date',
          },
          'New Node': {
            type: 'barcode',
            required: true,
            label: 'New Node',
            repeats: { min: 1, max: 1 },
          },
          'Replacement Comments (Opt)': {
            type: 'textArea',
            required: false,
            label: 'Replacement Comments (Opt)',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
        },
        order: ['Existing Node', 'Replacement Date', 'New Node', 'Replacement Comments (Opt)'],
      },
    },
    'Remove Node': {
      type: 'group',
      label: 'Remove Node',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          'Reason for Removal': {
            type: 'select',
            required: true,
            label: 'Reason for Removal',
            repeats: { min: 1, max: 1 },
            placeholder: 'placeholder',
            options: {
              type: 'SimpleOption',
              items: [
                {
                  value: 'Installed Node on Wrong Lantern',
                  label: 'Installed Node on Wrong Lantern',
                },
                { value: 'Column Knocked Down', label: 'Column Knocked Down' },
                { value: 'Column Being Removed', label: 'Column Being Removed' },
                { value: 'No Service to Lantern', label: 'No Service to Lantern' },
              ],
            },
          },
          '**Fixture will be reset to Not Yet Complete**': {
            type: 'textheader',
            label: '**Fixture will be reset to Not Yet Complete**',
          },
          'Scan Removed Node': {
            type: 'barcode',
            label: 'Scan Removed Node',
            repeats: { min: 1, max: 1 },
          },
          'Removal Date': {
            type: 'date',
            label: 'Removal Date',
            repeats: { min: 1, max: 1 },
            placeholder: 'Select date',
          },
          'Removal Comments (Opt)': {
            type: 'textArea',
            required: false,
            label: 'Removal Comments (Opt)',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
        },
        order: [
          'Reason for Removal',
          '**Fixture will be reset to Not Yet Complete**',
          'Scan Removed Node',
          'Removal Date',
          'Removal Comments (Opt)',
        ],
      },
    },
    'Set Retrofit Status': {
      type: 'radio',
      required: true,
      label: 'Set Retrofit Status',
      repeats: { min: 1, max: 1 },
      placeholder: 'placeholder',
      options: {
        type: 'SimpleOption',
        items: [
          { value: 'Not Yet Complete', label: 'Not Yet Complete' },
          { value: 'Complete with Issues', label: 'Complete with Issues' },
          { value: 'Unable to Complete', label: 'Unable to Complete' },
          { value: 'Removed', label: 'Removed' },
          { value: 'Completed', label: 'Completed' },
        ],
      },
    },
    '**Please Specify Issue Below**': {
      type: 'textheader',
      label: '**Please Specify Issue Below**',
    },
    Comments: {
      type: 'textArea',
      required: false,
      label: 'Comments',
      repeats: { min: 1, max: 1 },
      placeholder: 'Enter text',
    },
    Reason: {
      type: 'textArea',
      label: 'Reason',
      repeats: { min: 1, max: 1 },
      placeholder: 'Enter text',
    },
    'Metering Data': {
      type: 'group',
      label: 'Metering Data',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          Display: {
            type: 'radio',
            required: false,
            label: 'Display',
            repeats: { min: 1, max: 1 },
            placeholder: 'placeholder',
            options: {
              type: 'SimpleOption',
              items: [
                { value: 'Hide ', label: 'Hide ' },
                { value: 'Show', label: 'Show' },
              ],
            },
          },
          'Config Status': {
            type: 'textInput',
            required: false,
            label: 'Config Status',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Communication Status': {
            type: 'textInput',
            required: false,
            label: 'Communication Status',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Metered Power': {
            type: 'textInput',
            required: false,
            label: 'Metered Power',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Luminaire Wattage': {
            type: 'textInput',
            required: false,
            label: 'Luminaire Wattage',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Metered Current': {
            type: 'textInput',
            required: false,
            label: 'Metered Current',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Metered Voltage': {
            type: 'textInput',
            required: false,
            label: 'Metered Voltage',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
        },
        order: [
          'Display',
          'Config Status',
          'Communication Status',
          'Metered Power',
          'Luminaire Wattage',
          'Metered Current',
          'Metered Voltage',
        ],
      },
    },
    'Outage Data': {
      type: 'group',
      label: 'Outage Data',
      repeats: { min: 1, max: 1 },
      template: {
        components: {
          Display: {
            type: 'radio',
            required: false,
            label: 'Display',
            repeats: { min: 1, max: 1 },
            placeholder: 'placeholder',
            options: {
              type: 'SimpleOption',
              items: [
                { value: 'Hide', label: 'Hide' },
                { value: 'Show', label: 'Show' },
              ],
            },
          },
          Type: {
            type: 'checkbox',
            label: 'Type',
            repeats: { min: 1, max: 1 },
            placeholder: 'placeholder',
            options: {
              type: 'SimpleOption',
              items: [
                { value: 'Warning', label: 'Warning' },
                { value: 'Outage', label: 'Outage' },
              ],
            },
          },
          Failure: {
            type: 'textInput',
            required: false,
            label: 'Failure',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Last Update': {
            type: 'textInput',
            required: false,
            label: 'Last Update',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Failed Since': {
            type: 'textInput',
            required: false,
            label: 'Failed Since',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter text',
          },
          'Burning Hours': {
            type: 'numberInput',
            required: false,
            label: 'Burning Hours',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter number',
          },
          'Lifetime %': {
            type: 'numberInput',
            required: false,
            label: 'Lifetime %',
            repeats: { min: 1, max: 1 },
            placeholder: 'Enter number',
          },
        },
        order: [
          'Display',
          'Type',
          'Failure',
          'Last Update',
          'Failed Since',
          'Burning Hours',
          'Lifetime %',
        ],
      },
    },
  },
  order: [
    'Name',
    'Show Attributes',
    'Existing Attributes',
    'Select Action',
    'Install Node',
    'Replace Node',
    'Remove Node',
    'Set Retrofit Status',
    '**Please Specify Issue Below**',
    'Comments',
    'Reason',
    'Metering Data',
    'Outage Data',
  ],
};
