import NumberInputControl from './NumberInput';
import GroupControl from './GroupControl';
import TextInputControl from './TextInput';
import MapControl from './MapControl';
import StreetViewControl from './StreetViewControl';
import {
  V2GroupComponentDef,
  V2TextTemplateComponent,
  V2NumberTemplateComponent,
  V2MapComponent,
  V2StreetViewComponent,
} from '@terragotech/page-renderer';
import { PageControlProps, NestedPageControlProps } from '../PageTypes';

interface PageBuilderControlMap {
  group?: React.ComponentType<PageControlProps<V2GroupComponentDef>>;
  text?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  datetime?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  date?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  time?: React.ComponentType<NestedPageControlProps<V2TextTemplateComponent>>;
  number?: React.ComponentType<NestedPageControlProps<V2NumberTemplateComponent>>;
  aggregateMap?: React.ComponentType<NestedPageControlProps<V2MapComponent>>;
  streetView?: React.ComponentType<NestedPageControlProps<V2StreetViewComponent>>;
}

export const controlMap: PageBuilderControlMap = {
  group: GroupControl,
  text: TextInputControl,
  date: TextInputControl,
  time: TextInputControl,
  datetime: TextInputControl,
  number: NumberInputControl,
  aggregateMap: MapControl,
  streetView: StreetViewControl,
};
export { default as PageFrame } from './PageFrame';
