"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isV2EditableComponent = exports.isV2ValidationComponent = exports.isV2ConditionalComponent = exports.isV2RepeatableComponent = void 0;
function isV2RepeatableComponent(component) {
    const temp = component;
    return temp.repeats !== undefined;
}
exports.isV2RepeatableComponent = isV2RepeatableComponent;
function isV2ConditionalComponent(component) {
    const temp = component;
    return temp.conditionalMap !== undefined;
}
exports.isV2ConditionalComponent = isV2ConditionalComponent;
function isV2ValidationComponent(component) {
    const temp = component;
    return temp.errorMap !== undefined || temp.warningMap !== undefined || temp.required !== undefined;
}
exports.isV2ValidationComponent = isV2ValidationComponent;
function isV2EditableComponent(component) {
    const temp = component;
    return temp.readOnly !== undefined;
}
exports.isV2EditableComponent = isV2EditableComponent;
