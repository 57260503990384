/* This whole thing was temporary stolen from the terrago-form-lib repo, which is not yet actually a lib */
import { V2FormTemplate, V2FormComponentDef } from '@terragotech/form-renderer';
import { JSONSchema6 } from 'json-schema';

type TSEnum = string[];
interface TSObject {
  [key: string]: TSType;
}

type TSType = TSEnum | TSObject | 'string';

export const getPropertyObjectFromFormComponent = (FormComponent: V2FormComponentDef): JSONSchema6 => {
  switch (FormComponent.type) {
    case 'textInput':
    case 'textArea':
    case 'barcode':
    case 'audioupload':
    case 'videoupload':
    case 'computed':
      return { type: 'string' };
    case 'imageupload':
      return { type: 'string', format: 'photo' };
    case 'fileupload':
      return {
        type: 'object',
        properties: {
          id: { type: 'string' },
          name: { type: 'string' },
        },
      };
    case 'numberInput':
    case 'currency':
    case 'date':
    case 'time':
      return { type: 'number' };
    case 'radio':
    case 'select':
      return {
        type: 'string',
        enum:
          FormComponent.options.type === 'SimpleOption'
            ? FormComponent.options.items.map((option): string => option.value)
            : [],
      };
    case 'checkbox':
      return {
        type: 'array',
        items: {
          type: 'string',
          enum:
            FormComponent.options.type === 'SimpleOption'
              ? FormComponent.options.items.map((option): string => option.value)
              : [],
        },
      };
    case 'mapAggregateSelector':
      return {
        type: 'object',
        properties: {
          id: { type: 'string' },
          type: { type: 'string' },
          label: { type: 'string' },
        },
      };
    case 'location':
      return {
        type: 'object',
        properties: {
          location: { $ref: '/Geography/GeoJSON' },
          metadata: { type: 'object' },
        },
      };
    case 'polyline':
      return {
        type: 'object',
        properties: {
          polyline: { $ref: '/Geography/GeoJSON' },
          metadata: { type: 'object' },
        },
      };
    default:
      console.log(`Missing type ${FormComponent.type}`);
      return {};
  }
};

export const convertV2FormTemplateToJsonSchema = (v2FormTemplate: V2FormTemplate): JSONSchema6 => {

  const properties: { [k: string]: JSONSchema6 } = {};
  // now we're going to populate the data object with the form template
  v2FormTemplate &&
    v2FormTemplate.order &&
    v2FormTemplate.order.forEach((key): void => {
      const FormComponent = v2FormTemplate.components[key];
      // don't process textheaders, and at least for now, functions
      if (FormComponent && FormComponent.type !== 'textheader') {
        let property: JSONSchema6;
        // if it's a group, recurse and get all of its interfaces
        if (FormComponent.type === 'group') {
          property = convertV2FormTemplateToJsonSchema(FormComponent.template);
          if (
            FormComponent.repeats && FormComponent.repeats.enabled &&
            (FormComponent.repeats.max > 1 || FormComponent.repeats.max < 0)
          ) {
            properties[key] = {
              type: 'array',
              items: convertV2FormTemplateToJsonSchema(FormComponent.template),
            };
          } else {
            properties[key] = convertV2FormTemplateToJsonSchema(FormComponent.template);
          }
        }
        // if a regular component, add it to the current interface, unless it's a textheader or function(no types for those)
        else {
          property = getPropertyObjectFromFormComponent(FormComponent);
        }

        //special case for photos
        if (FormComponent.type === 'imageupload' || FormComponent.type === 'fileupload') {
          properties[key] = { type: 'array', items: property };
        } else if (
          //general case for all other repeats
          'repeats' in FormComponent &&
          FormComponent.repeats &&
          FormComponent.repeats.enabled &&
          (FormComponent.repeats.max > 1 || FormComponent.repeats.max < 0)
        ) {
          properties[key] = { type: 'array', items: property };
        } else if (
          FormComponent.type === 'location' &&
          FormComponent?.repeats?.enabled === false &&
          FormComponent?.multiplePoints?.enabled &&
          (FormComponent?.multiplePoints?.maximum > 1 ||
            FormComponent?.multiplePoints?.unlimited === true)
        ) {
          //special case for multiple points (array) on location type
          properties[key] = { type: 'array', items: property };
        } else {
          properties[key] = property;
        }
      }
    });

  return {
    type: 'object',
    properties,
  };
};
