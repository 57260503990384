import React, { useContext, useState, useMemo, useRef, useEffect } from 'react';
import {
  Paper,
  Divider,
  Button,
  TextField,
  InputAdornment,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@material-ui/core';
import _, { isEqual } from 'lodash';
import styled from 'styled-components';
import { useParams, Prompt } from 'react-router-dom';
import { ConfigContext } from '../../context/ConfigContext';
import { successMsg, warningMsg, errorMsg } from '../SnackbarUtilsConfigurator';
import { RadioInput, SelectInput } from '../FormElements';
import { CommandReference, ActionButton } from '@terragotech/gen5-config-lib';
import { getAggregatePropertiesList } from '../../utils/jsonPartsGenerators';
import { MaterialTableRef } from '../EditableTable';
import { ConditionalCardDefinition } from '@terragotech/gen5-config-lib/dist/CardDefinitions/ConditionalCardDefinition';
import { useUICustomizationAPI } from '../../context/fakeAPIHooks/useUICustomizationAPI';
import { BuiltinWebActions, MobileActionBuiltIns } from '@terragotech/gen5-config-lib';
import { CardDefinitionConditionalCard } from './CardDefinitionConditionalCard';
import { CardDefinitionOtherAttributes } from './CardDefinitionOtherAttributes';
import {
  CardDefinition as CardDefinitionType,
  BuildinActions,
  CardDefinitionOtherAttribute,
} from '../../utils/types';
import { MobileCardDefinition } from '@terragotech/gen5-config-lib/dist/CardDefinitions/MobileCardDefinition';
import { capitalCase } from 'change-case';
import { getCustomPageList } from '../../utils/helpers';

export type MobileActionButtonWithID = ActionButton<MobileActionBuiltIns> & {
  tableData: { id: number };
};
export type WebActionButtonWithID = ActionButton<BuiltinWebActions> & { tableData: { id: number } };
export type ActionButtonWithID = MobileActionButtonWithID | WebActionButtonWithID;

export type ButtonAction = CommandReference | MobileActionBuiltIns;

interface Props {
  type: 'mobile' | 'web';
}

export const CardDefinition: React.FC<Props> = ({ type }) => {
  const { config, getCardDefinition } = useContext(ConfigContext);
  const UICustomizationAPI = useUICustomizationAPI();
  const otherAttributesTableRef = useRef<MaterialTableRef>(null);
  const basicCardTableRef = useRef<MaterialTableRef>(null);
  const conditionalCardTableRef = useRef<MaterialTableRef>(null);

  const uiConfigType = type === 'mobile' ? 'mobileUIConfig' : 'webUIConfig';
  const { aggrUICustomization: aggrUIName } = useParams() as { aggrUICustomization: string };
  const cardDefinition = getCardDefinition(uiConfigType, aggrUIName);
  const template = cardDefinition?.template || 'conditionalCard';
  const [titleKey, setTitleKey] = useState(cardDefinition?.titleKey || '');
  const [cardHeight, setCardHeight] = useState(
    (cardDefinition as MobileCardDefinition)?.cardHeight || 370
  );

  const [secondaryButtonOptions, setSecondaryButtonOptions] = useState(
    (cardDefinition as MobileCardDefinition)?.secondaryButtonOptions || {
      groupButtons: false,
      groupButtonLabel: 'Actions',
    }
  );

  const aggregateProps = useMemo(() => getAggregatePropertiesList(config, aggrUIName), [
    config,
    aggrUIName,
  ]);

  const [otherAttributes, setOtherAttributes] = useState(cardDefinition?.otherAttributes || []);

  useEffect(() => {
    const othrAttr = otherAttributes;
    const otherAttribute: CardDefinitionOtherAttribute[] = [];
    for (const attribute of othrAttr) {
      const attrType = _.find(aggregateProps, { name: attribute.itemKey });
      otherAttribute.push({ ...attribute, type: attribute.type ? attribute.type : attrType?.type });
    }
    setOtherAttributes(otherAttribute);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [buttons, setButtons] = useState(
    (cardDefinition as ConditionalCardDefinition<BuildinActions>)?.buttons || []
  );

  const [showTitleLabel, setShowTitleLabel] = useState(cardDefinition?.showTitleLabel);

  const [attributesType, setAttributesType] = useState(!!cardDefinition?.pageName ? 'customPage' : 'otherAttributes');
  const [pageName, setPageName] = useState(cardDefinition?.pageName ?? '');

  const getCardDefinitionForm = () => ({
    template,
    titleKey,
    showTitleLabel,
    ...(uiConfigType === 'mobileUIConfig' && { cardHeight }),
    ...(uiConfigType === 'mobileUIConfig' && { secondaryButtonOptions }),
    otherAttributes,
    ...(attributesType === 'customPage' && { pageName }),
    ...(template === 'conditionalCard' && { buttons }),
  });

  const handleSave = async () => {
    if (!titleKey) return errorMsg('Title Key field is required');
    if (secondaryButtonOptions.groupButtons) {
      if (!secondaryButtonOptions.groupButtonLabel?.trim().length)
        return errorMsg('Group button label field is required');
    } else {
      delete secondaryButtonOptions.groupButtonLabel;
    }
    if (attributesType === 'customPage' && !pageName)
      return errorMsg('Custom Page name is required');

    const { lastEditingRow: lastEditingRow1, showAddRow: showAddRow1 } =
      basicCardTableRef?.current?.state || {};
    const { lastEditingRow: lastEditingRow2, showAddRow: showAddRow2 } =
      conditionalCardTableRef?.current?.state || {};
    const { lastEditingRow: lastEditingRow3, showAddRow: showAddRow3 } =
      otherAttributesTableRef?.current?.state || {};
    if (
      lastEditingRow1 ||
      showAddRow1 ||
      lastEditingRow2 ||
      showAddRow2 ||
      lastEditingRow3 ||
      showAddRow3
    )
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );

    const { error } = await UICustomizationAPI.updateCardDefinition(
      uiConfigType,
      aggrUIName,
      getCardDefinitionForm() as CardDefinitionType
    );
    if (error) return;
    return successMsg('Updated successfully');
  };

  const ifShowMessageBeforeRedirect = () => {
    const { lastEditingRow: lastEditingRow1, showAddRow: showAddRow1 } =
      basicCardTableRef?.current?.state || {};
    const { lastEditingRow: lastEditingRow2, showAddRow: showAddRow2 } =
      conditionalCardTableRef?.current?.state || {};
    const { lastEditingRow: lastEditingRow3, showAddRow: showAddRow3 } =
      otherAttributesTableRef?.current?.state || {};
    return !isEqual(cardDefinition, getCardDefinitionForm()) ||
      lastEditingRow1 ||
      showAddRow1 ||
      lastEditingRow2 ||
      showAddRow2 ||
      lastEditingRow3 ||
      showAddRow3
      ? 'The form has not been saved, do you want to redirect?'
      : true;
  };

  const handleSecondaryButtonOptionsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSecondaryButtonOptions({
      ...secondaryButtonOptions,
      groupButtons: !secondaryButtonOptions.groupButtons,
    });
  };

  return (
    <PropertiesContainer>
      <HeaderContainer>
        <CardTitle>Card Definition</CardTitle>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          style={{ margin: '10px 0' }}
        >
          Save
        </Button>
      </HeaderContainer>
      <GroupDivider />
      <SelectInput
        title="Title Key"
        value={titleKey}
        onChange={(value: string) => setTitleKey(value)}
        options={_.map(aggregateProps, function (element, idx) {
          return element.name;
        })}
      />
      <HeaderContainer>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={showTitleLabel}
                onChange={() => setShowTitleLabel(!showTitleLabel)}
                color="primary"
              />
            }
            label="Show a label for the card's title"
          />
          {showTitleLabel && titleKey && (
            <FormHelperText>
              The label is currently set to: <b>{capitalCase(titleKey)}</b>
            </FormHelperText>
          )}
        </div>

        <img
          alt=""
          src={require(`../../resources/images/title-label-${showTitleLabel ? 'on' : 'off'}.png`)}
        />
      </HeaderContainer>

      {uiConfigType === 'mobileUIConfig' && (
        <>
          <TextField
            label="Card Height"
            helperText="Defualt card height is 370px"
            fullWidth
            margin="dense"
            type="number"
            value={cardHeight}
            onChange={(e) => setCardHeight(Number(e.target.value))}
            InputProps={{
              endAdornment: <InputAdornment position="start">px</InputAdornment>,
            }}
            variant="outlined"
          />
          <FormControlLabel
            control={
              <Switch
                checked={secondaryButtonOptions.groupButtons}
                onChange={handleSecondaryButtonOptionsToggle}
                color="primary"
              />
            }
            label="Group secondary action buttons"
          />

          <FormHelperText>
            Enabeling this will show a single button, that when clicked, will bring up a menu of
            action buttons. Enable this when users will seldom use secondary action buttons, to
            clean up the card.
          </FormHelperText>
          {secondaryButtonOptions.groupButtons && (
            <TextField
              label="Grouped secondary action button label"
              fullWidth
              margin="dense"
              style={textFieldStyle}
              value={secondaryButtonOptions.groupButtonLabel || ''}
              onChange={(e) =>
                setSecondaryButtonOptions({
                  ...secondaryButtonOptions,
                  groupButtonLabel: e.target.value,
                })
              }
              variant="outlined"
            />
          )}
        </>
      )}
      
      <RadioInput
        title="Type"
        value={attributesType}
        onChange={(value) => setAttributesType(value)}
        options={[
          { value: 'otherAttributes', label: 'Other Attributes' },
          { value: 'customPage', label: 'Custom Page' },
        ]}
      />

      {attributesType === 'otherAttributes' && (
        <CardDefinitionOtherAttributes
          otherAttributesTableRef={otherAttributesTableRef}
          otherAttributes={otherAttributes}
          setOtherAttributes={setOtherAttributes}
        />
      )}

      {attributesType === 'customPage' && (
        <SelectInput
          title="Custom Page"
          options={getCustomPageList(config,aggrUIName)}
          onChange={(value) => setPageName(value)}
          value={pageName}
        />
      )}

      <CardDefinitionConditionalCard
        type={type}
        buttons={buttons}
        setButtons={setButtons}
        conditionalCardTableRef={conditionalCardTableRef}
      />
      <Prompt message={ifShowMessageBeforeRedirect} />
    </PropertiesContainer>
  );
};

const textFieldStyle = { marginTop: '16px' };

const PropertiesContainer = styled(Paper)`
  padding: 10px 20px;
  margin: 5px;
  flex: 1;
  overflow-x: auto;
`;

const CardTitle = styled.p`
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
    margin-bottom: 10px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
