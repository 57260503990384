import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { metadataSchema, updateAssetLocationSchema } from 'utils/jsonPartsGenerators';
import { Button, TextField, FormLabel, Tooltip, Grid } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {
  FormCommandDefinition,
  CommandVersionDefinition,
  OneToOneCommandToEventMapping,
  CommandToEventMapping,
} from '@terragotech/gen5-config-lib';
import { V2FormTemplate } from '@terragotech/form-renderer';

import { useConfig } from 'context/ConfigContext';
import { InputGroup, RadioInput, CheckboxInput, SelectInput } from 'components/FormElements';
import { errorMsg, successMsg, warningMsg } from 'components/SnackbarUtilsConfigurator';
import { cloneDeep, isEqual } from 'lodash';
import { MaterialTableRef } from 'components/EditableTable';
import { useConfirm } from 'material-ui-confirm';
import { convertV2FormTemplateToJsonSchema } from '../../utils/V2FormTemplateToJsonSchema';
import { generateEventSchema } from 'utils/jsonPartsGenerators';
import { cleanFormTemplate } from '../../utils/formUtils';
import { useParams, Prompt } from 'react-router-dom';
import { getAggregateIndex } from 'utils/navigationUtils';
import { useAggregateAPI } from 'context/fakeAPIHooks/useAggregateAPI';
import { FormCommandEditor } from './components/FormCommandEditor';
import DataMapperDialog from 'components/FormDialog/DataMapperDialog';
import { defaultMapperObject } from 'utils/defaultMapperObject';
import { JSONSchema6 } from 'json-schema';
import { convertV2FormTemplateToCommandDataSchema } from '../../utils/V2FormTemplateToCommandDataSchema';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import {
  useStyles,
  AggregateGeneralInfoContainer,
  CardTitle,
  ButtonContainer,
  CenterDiv,
  GroupDivider,
} from './CommandEditor.styled';
import WorkflowSimulatorModal from 'components/PageDialog/WorkflowSimulatorModal';
import WorkflowPageContainer from 'pages/workflow/WorkflowPageContainer';

type CommandTypes = 'FORM' | 'BUTTON' | 'INTERNAL';
export const CommandEditor: React.FC = () => {
  const { config, setConfig, getEventSchema } = useConfig();
  const AggregateAPI = useAggregateAPI();
  const classes = useStyles();
  const [showSimulator, setShowSimulator] = useState(false);
  const [showWorkflow, setShowWorkflow] = useState(false);
  const [simulatorAggregateId, setSimulatorAggregateId] = useState<string | undefined>();

  const { aggregate: aggrName, command: commandName, commandVersion } = useParams() as {
    aggregate: string;
    command: string;
    commandVersion: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);
  const tableRef = useRef<MaterialTableRef>(null);

  const [reset, setReset] = useState<number>(0);

  const confirm = useConfirm();
  const version = config.aggregates[aggrIndex]?.commands?.[commandName]?.versions[+commandVersion];
  const commandTitle = config.aggregates[aggrIndex]?.commands?.[commandName]?.defaultLabel;
  const aggregateTypeName = config.aggregates[aggrIndex]?.objectStore?.table;
  const aggregateLabelProperty = config.aggregates[aggrIndex]?.labelProperty || 'title';

  const initialValue = (
    fabOptionEnabled: boolean | undefined,
    isCreationCommand: boolean | undefined,
    hasNoAggregateIdCommand: boolean | undefined
  ) => {
    //If fabOptionEnabled checkbox not checked, no radio value assigned
    if (!(fabOptionEnabled || isCreationCommand || hasNoAggregateIdCommand)) {
      return;
    }
    let radioValue = 'UPDATE';
    if (isCreationCommand) {
      radioValue = 'CREATE';
    } else if (hasNoAggregateIdCommand) {
      radioValue = 'NONAGGREGATE';
    }
    return radioValue;
  };

  const [fabOptionEnabled, setfabOptionEnabled] = useState(
    version?.fabOptionEnabled || version?.isCreationCommand || version?.hasNoAggregateIdCommand
  );
  const [fabOptionEnabledRadio, setfabOptionEnabledRadio] = useState(
    initialValue(
      version?.fabOptionEnabled,
      version?.isCreationCommand,
      version?.hasNoAggregateIdCommand
    )
  );

  const [confirmationText, setConfirmationText] = useState(version?.confirmationText || '');
  const [fileTypes, setFileTypes] = useState(version?.fileTypes || 'xlsx,csv');
  const [confirmationTextToggle, setConfirmationTextToggle] = useState(
    confirmationText.length ? true : false
  );
  const [limitMaxOccurrence, setLimitMaxOccurrence] = useState(version?.limitMaxOccurrence || null);
  const [confirmationOccurrenceToggle, setConfirmationOccurrenceToggle] = useState(
    limitMaxOccurrence?.toString().length ? true : false
  );
  const [commandType, setCommandType] = useState<CommandTypes>(
    version?.commandType as CommandTypes
  );
  const [isCreationCommand, setIsCreationCommand] = useState(version?.isCreationCommand);
  const [isSynchronous, setIsSynchronous] = useState(version?.isSynchronous);
  const [isPublic, setIsPublic] = useState(version?.isPublic);
  const [isImportCommand, setIsImportCommand] = useState(version?.isImportCommand);
  const [emptyIsNull, setEmptyIsNull] = useState(version?.emptyIsNull);
  const [isIntegrationImport, setIsIntegrationImport] = useState(version?.isIntegrationImport);
  const [hasNoAggregateIdCommand, setHasNoAggregateIdCommand] = useState(
    version?.hasNoAggregateIdCommand
  );
  const [mappingType, setMappingType] = useState(version?.cmdToEventsMapping.mappingType);
  // Have to memoize this, unfortunately, because it's a hook dependency later
  const initialEventName = useMemo(
    () =>
      version?.cmdToEventsMapping && 'eventName' in version?.cmdToEventsMapping
        ? version?.cmdToEventsMapping.eventName
        : '',
    // eslint-disable-next-line
    [version]
  );
  const [eventName, setEventName] = useState(initialEventName);
  const [mapping, setMapping] = useState(
    (version?.cmdToEventsMapping as OneToOneCommandToEventMapping)?.mapping
  );
  const [formDefinition, setFormDefinition] = useState<V2FormTemplate>(
    (version &&
      (version as FormCommandDefinition).formDefinition &&
      cloneDeep((version as FormCommandDefinition).formDefinition)) || {
      components: {},
      order: [],
    }
  );
  const [mapOpen, setMapOpen] = useState(false);

  const handleSetMappingType = useCallback((type?: CommandToEventMapping['mappingType']) => {
    switch (type) {
      case 'ONE-TO-MANY':
        setMapping((curVal) => ({
          ...curVal,
          outputDefinition: { outputQuery: { types: ['OUTPUT-EVENT'] } },
        }));
        break;
      case 'COPY':
      case 'ONE-TO-ONE':
      default:
        setMapping((curVal) => ({ ...curVal, outputDefinition: { outputNode: 'OUTPUT' } }));
    }
    setMappingType(type);
  }, []);

  useEffect(() => {
    if (version) {
      const {
        commandType,
        isCreationCommand,
        isSynchronous,
        isPublic,
        isImportCommand,
        emptyIsNull,
        isIntegrationImport,
        hasNoAggregateIdCommand,
        cmdToEventsMapping,
        fileTypes,
        confirmationText,
        fabOptionEnabled,
        limitMaxOccurrence,
      } = version;
      //TODO: Choice type is not currently supported
      if (commandType !== 'CHOICE') {
        setCommandType(commandType);
        setIsCreationCommand(isCreationCommand);
        setIsSynchronous(isSynchronous);
        setIsPublic(isPublic);
        setHasNoAggregateIdCommand(hasNoAggregateIdCommand);
        setfabOptionEnabled(fabOptionEnabled || isCreationCommand || hasNoAggregateIdCommand);
        setfabOptionEnabledRadio(
          initialValue(fabOptionEnabled, isCreationCommand, hasNoAggregateIdCommand)
        );
        handleSetMappingType(cmdToEventsMapping?.mappingType);
        setEventName(initialEventName);
        setConfirmationText(confirmationText || '');
        setConfirmationTextToggle(confirmationText?.length ? true : false);
        setConfirmationOccurrenceToggle(limitMaxOccurrence ? true : false);
        setLimitMaxOccurrence(limitMaxOccurrence || null);
        //TODO: Re-enable if Choice type is brought back
        /*if (commandType === 'CHOICE') {
        setChoices((version as ChoiceCommandDefinition).choices || []);
      }*/
        if (commandType === 'FORM') {
          const formDefinition = (version as FormCommandDefinition).formDefinition;
          setIsImportCommand(isImportCommand);
          setEmptyIsNull(isImportCommand && emptyIsNull);
          setIsIntegrationImport(isImportCommand && isIntegrationImport);
          setFileTypes(
            isImportCommand && isIntegrationImport && fileTypes ? fileTypes : 'xlsx,csv'
          );
          setFormDefinition(
            (formDefinition && cloneDeep(formDefinition)) || {
              components: {},
              order: [],
            }
          );
          setMapping(
            (cmdToEventsMapping as OneToOneCommandToEventMapping)?.mapping || defaultMapperObject
          );
        }
      }
    }
  }, [commandVersion, handleSetMappingType, initialEventName, version, reset]); // eslint-disable-line react-hooks/exhaustive-deps

  const getEventList = () => {
    const { events } = config?.aggregates?.[aggrIndex];
    return events ? [...Object.keys(events), 'DeleteEvent'] : [];
  };

  const updateSchema = async () => {
    // regenerate the selected eventschema using the current form template
    await confirm({
      description: `This will overwrite any existing eventSchema for this event`,
      confirmationText: 'Save',
    });
    let eventSchema = null;
    if (commandType === 'FORM') {
      const dataSchema = convertV2FormTemplateToJsonSchema(formDefinition);
      eventSchema = generateEventSchema(eventName, dataSchema);
    } else if (commandType === 'INTERNAL') {
      //Only internal command right now is updateAssetLocation
      eventSchema = generateEventSchema(eventName, updateAssetLocationSchema);
    }
    if (eventSchema) {
      //now we have the schema, update the existing value in the aggregates
      //TODO: support versioning of events with this process
      const newConfig = cloneDeep(config);
      const events = newConfig?.aggregates?.[aggrIndex].events;
      if (events) {
        events[eventName].versions[+commandVersion].eventSchema = eventSchema;
      }
      setConfig(newConfig);
      successMsg(`The schema for ${eventName} has been updated`);
    } else {
      errorMsg(`Could not generate Schema for the selections given`);
    }
  };

  const save = async () => {
    if (!checkLimitMaxOccurrence())
      return errorMsg('The limit to max occurrence must be defined and above 0');
    if (!checkConfirmationText()) return errorMsg('Confirmation Text is required');
    const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
    if (lastEditingRow || showAddRow)
      return warningMsg(
        'Editing of the table is not completed, confirm or reject the edited row before proceed with saving the form'
      );
    const oldCommandType =
      config?.aggregates?.[aggrIndex].commands?.[commandName].versions?.[+commandVersion]
        .commandType;
    if (commandType !== oldCommandType)
      await confirm({
        description: `By saving command with a changed command type, configuration specific to the previous type will be removed`,
        confirmationText: 'Save',
      });

    const { error } = await AggregateAPI.updateCommandVersion(
      aggrIndex,
      commandName,
      +commandVersion,
      getNewCommand() as CommandVersionDefinition
    );
    if (error) return;
    successMsg('The command has been saved');
  };

  const getNewCommand = () => ({
    ...version,
    commandType,
    isCreationCommand,
    ...(isSynchronous !== undefined && isSynchronous !== null && { isSynchronous }),
    ...(isPublic !== undefined && isPublic !== null && { isPublic }),
    ...(isImportCommand !== undefined &&
      isImportCommand !== null &&
      commandType === 'FORM' && {
        isImportCommand,
        emptyIsNull: isImportCommand && emptyIsNull,
        isIntegrationImport: isImportCommand && isIntegrationImport,
        fileTypes: isImportCommand && isIntegrationImport && fileTypes,
      }),
    ...(hasNoAggregateIdCommand !== undefined &&
      hasNoAggregateIdCommand !== null && { hasNoAggregateIdCommand }),
    ...(fabOptionEnabled !== undefined && fabOptionEnabled !== null && { fabOptionEnabled }),
    ...(commandType === 'FORM' && { formDefinition: cleanFormTemplate(formDefinition) }),
    ...(confirmationText.length && { confirmationText }),
    ...(limitMaxOccurrence !== null && { limitMaxOccurrence }),
    cmdToEventsMapping: {
      mappingType,
      eventName,
      ...(mappingType === 'ONE-TO-ONE' && { mapping }),
      ...(mappingType === 'ONE-TO-MANY' && { mapping }),
    },
    //TODO: Re-enable if choic type is brought back
    //...(choices.length !== 0 && commandType === 'CHOICE' && { choices }),
  });

  const ifShowMessageBeforeRedirect = () => {
    const command =
      config.aggregates[aggrIndex].commands?.[commandName]?.versions?.[+commandVersion];
    if (command) {
      const { lastEditingRow, showAddRow } = tableRef?.current?.state || {};
      return !isEqual(getNewCommand(), command) || lastEditingRow || showAddRow
        ? 'The form has not been saved, do you want to redirect?'
        : true;
    } else return true;
  };

  const handleSetEventName = async (value: string) => {
    if (mappingType === 'ONE-TO-ONE' && !isEqual(mapping, defaultMapperObject)) {
      await confirm({
        description: 'By changing the event, the actual mapping will be lost, are you sure?',
        confirmationText: 'Change',
      });
      setMapping(defaultMapperObject);
    }
    setEventName(value);
  };

  const handleClearMap = async () => {
    await confirm({
      description: `The mapping will be cleared`,
      confirmationText: 'Clear',
    });
    if (mappingType === 'ONE-TO-ONE') {
      setMapping(mapScenarios['COMMAND_EVENT_MAPPING'].defaultNodeMap);
    } else if (mappingType === 'ONE-TO-MANY') {
      setMapping(mapScenarios['COMMAND_MULTI_EVENT_MAPPING'].defaultNodeMap);
    } else {
      setMapping(defaultMapperObject);
    }
  };

  const onConfirmationtxtToggle = (val: boolean) => {
    val ? setConfirmationText('Are you sure you want to do this?') : setConfirmationText('');
    setConfirmationTextToggle(val);
  };

  const onConfirmationOccurenceToggle = (val: boolean) => {
    val ? setLimitMaxOccurrence(50) : setLimitMaxOccurrence(null);
    setConfirmationOccurrenceToggle(!confirmationOccurrenceToggle);
  };
  const onChangeConfirmationOccurrence = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitMaxOccurrence(+e.target.value);
  };
  const checkConfirmationText = (): boolean => {
    let validConfrmtnTxt = true;
    if (confirmationTextToggle) {
      if (!confirmationText.trim().length) validConfrmtnTxt = false;
    } else {
      setConfirmationText('');
      delete version?.confirmationText;
    }
    return validConfrmtnTxt;
  };

  const checkLimitMaxOccurrence = (): boolean => {
    let validLimit = true;
    if (confirmationOccurrenceToggle) {
      if (!limitMaxOccurrence || limitMaxOccurrence <= 0) validLimit = false;
    } else {
      setLimitMaxOccurrence(null);
      delete version?.limitMaxOccurrence;
    }
    return validLimit;
  };

  const handleCancelChanges = async () => {
    try {
      await confirm({
        title: 'Are you sure you want to cancel these changes?',
        description: 'This cannot be undone.',
        confirmationText: 'Confirm',
      });
      setReset((prev) => prev + 1);
    } catch (error) {
      console.log('request cancelled');
    }
  };

  const handleClickSimulate = async () => {
    if (ifShowMessageBeforeRedirect() !== true) {
      await confirm({
        title: 'You must save before simulating. Save now?',
        confirmationText: 'Save',
      });
      save();
    }
    if (!fabOptionEnabledRadio) {
      setShowSimulator(true);
    } else {
      setShowWorkflow(true);
    }
  };

  return (
    <AggregateGeneralInfoContainer>
      <Grid container direction="row" justifyContent="space-between">
        <CardTitle>Command Editor</CardTitle>
        {commandType === 'FORM' && (
          <Button size="small" color="primary" onClick={handleClickSimulate}>
            Simulate
          </Button>
        )}
      </Grid>
      <GroupDivider />
      <RadioInput
        title="Type"
        value={commandType}
        onChange={(value) => setCommandType(value as CommandTypes)}
        options={[
          { value: 'BUTTON', label: 'Button' },
          { value: 'FORM', label: 'Form' },
          { value: 'INTERNAL', label: 'Internal' },
          { value: 'NUMBER', label: 'Number' },
        ]}
      />
      <FormLabel component="legend" style={{ fontSize: 12, marginBottom: 0, paddingTop: 10 }}>
        Options
      </FormLabel>
      {!isImportCommand && (
        <CenterDiv>
          <CheckboxInput
            title="Add to FAB"
            labelStyle={{ color: 'black' }}
            checked={fabOptionEnabled}
            onChange={(value) => {
              if (value === false) {
                setIsCreationCommand(false);
                setHasNoAggregateIdCommand(false);
                setfabOptionEnabledRadio(undefined);
              } else {
                setfabOptionEnabledRadio('UPDATE');
              }
              setfabOptionEnabled(value);
            }}
          />
          <Tooltip
            placement="top"
            style={{ marginLeft: '-21px' }}
            classes={{ tooltip: classes.tooltip }}
            title={`Use this checkbox to enable update, create, and non-aggregate commands`}
          >
            <HelpOutlineOutlinedIcon style={{ fontSize: 17, marginLeft: -13 }} color="action" />
          </Tooltip>
        </CenterDiv>
      )}
      {!isImportCommand && fabOptionEnabled && (
        <RadioInput
          title=""
          value={fabOptionEnabledRadio}
          containerStyle={{ margin: '-20px -10px -15px 50px' }}
          onChange={(value) => {
            setHasNoAggregateIdCommand(false);
            setIsCreationCommand(false);
            if (value === 'CREATE') {
              setIsCreationCommand(true);
            } else if (value === 'NONAGGREGATE') {
              setHasNoAggregateIdCommand(true);
            }
            setfabOptionEnabledRadio(value);
          }}
          options={[
            { value: 'UPDATE', label: 'Update' },
            { value: 'CREATE', label: 'Create' },
            { value: 'NONAGGREGATE', label: 'Non-Aggregate' },
          ]}
        />
      )}
      <CheckboxInput
        title="Is synchronous"
        labelStyle={{ color: 'black' }}
        checked={isSynchronous}
        onChange={(value) => setIsSynchronous(value)}
      />
      <CheckboxInput
        title="Is public"
        labelStyle={{ color: 'black' }}
        checked={isPublic}
        onChange={(value) => setIsPublic(value)}
      />
      {commandType === 'FORM' && (
        <CheckboxInput
          title="Is Import Command"
          labelStyle={{ color: 'black' }}
          checked={isImportCommand}
          onChange={async (value) => {
            if (value) {
              try {
                await confirm({
                  title: 'Are you sure you want to make this an import command?',
                  description: 'This will clear all form information and cannot be undone.',
                  confirmationText: 'Confirm',
                });

                setFormDefinition({
                  components: {},
                  order: [],
                });
                setIsCreationCommand(false);
                setHasNoAggregateIdCommand(false);
                setfabOptionEnabledRadio(undefined);
                setfabOptionEnabled(false);
                setIsImportCommand(true);
              } catch (error) {
                console.log('import command activation cancelled');
              }
            } else {
              setIsImportCommand(value);
            }
          }}
        />
      )}

      {commandType === 'FORM' && isImportCommand && (
        <InputGroup title="Import Options" style={{ margin: '10px 0' }}>
          {!isIntegrationImport && (
            <CheckboxInput
              title="Treat empty cells as null"
              labelStyle={{ color: 'black' }}
              checked={emptyIsNull}
              onChange={(value) => setEmptyIsNull(value)}
            />
          )}{' '}
          <CheckboxInput
            title="Is Integration Import"
            labelStyle={{ color: 'black' }}
            checked={isIntegrationImport}
            onChange={async (value) => {
              if (value) {
                try {
                  await confirm({
                    title: 'Are you sure you want to make this an integration import?',
                    description: 'This will clear all form information and cannot be undone.',
                    confirmationText: 'Confirm',
                  });

                  setFormDefinition({
                    components: {
                      s3Filename: {
                        type: 'textInput',
                        label: 'S3 File Name',
                        placeholder: 'Enter text',
                        required: true,
                      },
                      importId: {
                        type: 'textInput',
                        label: 'S3 File Name',
                        placeholder: 'Enter text',
                        required: true,
                      },
                    },
                    order: ['s3Filename', 'importId'],
                  });
                  setIsIntegrationImport(true);
                } catch (error) {
                  console.log('import command activation cancelled');
                }
              } else {
                setIsIntegrationImport(value);
                setFormDefinition({
                  components: {},
                  order: [],
                });
              }
            }}
          />
          {isIntegrationImport && (
            <TextField
              id="fileTypes"
              label="Comma-separated allowed file types"
              fullWidth={true}
              multiline
              margin="dense"
              variant="outlined"
              value={fileTypes}
              onChange={(e) => setFileTypes(e?.target.value)}
            />
          )}
        </InputGroup>
      )}

      <CenterDiv>
        <CheckboxInput
          title="Command requires user confirmation"
          labelStyle={{ color: 'black' }}
          checked={confirmationTextToggle}
          onChange={onConfirmationtxtToggle}
        />
        <Tooltip
          placement="top"
          style={{ marginLeft: '-21px' }}
          classes={{ tooltip: classes.tooltip }}
          title={`For commands like "delete", the web and mobile apps will prompt the user for confirmation before the command executes`}
        >
          <HelpOutlineOutlinedIcon style={{ fontSize: 17, marginLeft: -13 }} color="action" />
        </Tooltip>
      </CenterDiv>
      {confirmationTextToggle && (
        <div style={{ marginLeft: 30 }}>
          <TextField
            id="confirmationText"
            label="Confirmation Text"
            fullWidth={true}
            multiline
            margin="dense"
            variant="outlined"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e?.target.value)}
          />
        </div>
      )}
      <CenterDiv>
        <CheckboxInput
          title="Limit max occurrence"
          labelStyle={{ color: 'black' }}
          checked={confirmationOccurrenceToggle}
          onChange={onConfirmationOccurenceToggle}
        />
        <Tooltip
          placement="top"
          style={{ marginLeft: '-21px' }}
          classes={{ tooltip: classes.tooltip }}
          title={`Limit the amount of times this command can be run at once, during a multi select action.`}
        >
          <HelpOutlineOutlinedIcon style={{ fontSize: 17, marginLeft: -13 }} color="action" />
        </Tooltip>
      </CenterDiv>
      {confirmationOccurrenceToggle && (
        <div style={{ marginLeft: 30 }}>
          <TextField
            type="number"
            id="confirmationOccurrence"
            onChange={onChangeConfirmationOccurrence}
            value={limitMaxOccurrence}
            label="Max Occurence"
            variant="outlined"
            InputProps={{
              inputProps: {
                min: 1,
              },
            }}
          />
        </div>
      )}
      <InputGroup
        title="Command To Event Mapping"
        style={{ paddingTop: 15, overflowY: 'auto', margin: '20px 0' }}
        titleStyle={{ transform: 'translate(-36px, -44px) scale(0.75)', zIndex: 100 }}
      >
        <SelectInput
          title="Mapping Type"
          options={['COPY', /*'ONE-TO-ONE', */ 'ONE-TO-MANY']}
          onChange={(value) => handleSetMappingType(value || undefined)}
          value={mappingType || ''}
        />
        {mappingType !== 'ONE-TO-MANY' && (
          <SelectInput
            title="Event Name"
            options={getEventList()}
            onChange={(value) => handleSetEventName(value)}
            value={eventName}
          />
        )}
        {mappingType === 'COPY' && (
          <Button
            color="default"
            variant="contained"
            onClick={updateSchema}
            style={{ margin: '10px 0' }}
          >
            Update Schema
          </Button>
        )}
        {mappingType === 'ONE-TO-ONE' && eventName && (
          <InputGroup title="Map" style={{ margin: '10px 0' }}>
            <Button onClick={() => setMapOpen(true)}>Advanced</Button>
            <Button onClick={handleClearMap}>Clear</Button>
            <DataMapperDialog
              mapScenario="COMMAND_EVENT_MAPPING"
              localSchemaDefinitions={{
                METADATA: {
                  schema: metadataSchema,
                  schemaLabel: 'Metadata',
                },
                EVENT: {
                  schema:
                    (getEventSchema(aggrIndex, eventName)?.properties?.data as JSONSchema6) || {},
                  schemaLabel: eventName,
                },
                DATA: {
                  schema: convertV2FormTemplateToJsonSchema(formDefinition),
                  schemaLabel: 'Form Definition',
                },
                COMMAND_DATA: {
                  schema: convertV2FormTemplateToCommandDataSchema(formDefinition),
                  schemaLabel: 'Command Data',
                },
              }}
              onClose={() => {
                setMapOpen(false);
              }}
              open={mapOpen}
              datamap={mapping || defaultMapperObject}
              setDatamap={(data) => setMapping(data || defaultMapperObject)}
            />
          </InputGroup>
        )}
        {mappingType === 'ONE-TO-MANY' && (
          <InputGroup title="Map" style={{ margin: '10px 0' }}>
            <Button onClick={() => setMapOpen(true)}>Advanced</Button>
            <Button onClick={handleClearMap}>Clear</Button>
            <DataMapperDialog
              mapScenario="COMMAND_MULTI_EVENT_MAPPING"
              localSchemaDefinitions={{
                METADATA: {
                  schema: metadataSchema,
                  schemaLabel: 'Metadata',
                },
                STATE: {
                  schema: metadataSchema,
                  schemaLabel: 'Metadata',
                },
                DATA: {
                  schema: convertV2FormTemplateToJsonSchema(formDefinition),
                  schemaLabel: 'Form Definition',
                },
                COMMAND_DATA: {
                  schema: convertV2FormTemplateToCommandDataSchema(formDefinition),
                  schemaLabel: 'Command Data',
                },
              }}
              onClose={() => {
                setMapOpen(false);
              }}
              open={mapOpen}
              datamap={mapping}
              setDatamap={(data) => setMapping(data || defaultMapperObject)}
            />
          </InputGroup>
        )}
      </InputGroup>

      {commandType === 'FORM' && (!isImportCommand || !isIntegrationImport) && (
        <FormCommandEditor
          isImportCommand={isImportCommand}
          formDefinition={formDefinition}
          setFormDefinition={setFormDefinition}
        />
      )}
      <ButtonContainer>
        <Button
          color="default"
          variant="contained"
          onClick={handleCancelChanges}
          style={{
            backgroundColor: 'white',
            color: 'black',
          }}
        >
          Cancel
        </Button>
        <Button color="primary" variant="contained" onClick={save}>
          Save
        </Button>
      </ButtonContainer>
      <Prompt message={ifShowMessageBeforeRedirect} />
      {showSimulator && (
        <WorkflowSimulatorModal
          aggregateType={aggrName}
          aggregateQueryKey={aggregateTypeName}
          aggregateLabelProperty={aggregateLabelProperty}
          onClose={() => setShowSimulator(false)}
          onRun={(aggregateId: string) => {
            setShowSimulator(false);
            setShowWorkflow(true);
            setSimulatorAggregateId(aggregateId);
          }}
        />
      )}
      {showWorkflow && (
        <WorkflowPageContainer
          onCancel={() => {
            setShowWorkflow(false);
            setSimulatorAggregateId(undefined);
          }}
          onDonePress={() => {
            setShowWorkflow(false);
            setSimulatorAggregateId(undefined);
          }}
          aggregateType={aggrName}
          targetAggregateId={simulatorAggregateId}
          creationAction={isCreationCommand}
          title={commandTitle || ''}
          command={{
            __typename: 'CommandReference',
            commandName,
            commandVersion: parseInt(commandVersion) + 1,
            isSynchronous: !!isSynchronous,
            hasNoAggregateIdCommand: !!hasNoAggregateIdCommand,
            confirmationText,
            limitMaxOccurrence: limitMaxOccurrence || undefined,
            command: {
              template: formDefinition,
            },
          }}
        />
      )}
    </AggregateGeneralInfoContainer>
  );
};
