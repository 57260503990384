import { V2PageComponentDef } from '@terragotech/page-renderer';
import { camelCaseToSpaceSeparated } from '../../../../components/FormElements';

export const createPageItem = (type: string, name: string): V2PageComponentDef => {
  switch (type) {
    case 'text':
      return {
        type: 'text' as const,
        label: name,
      };
    case 'number':
      return {
        type: 'number' as const,
        label: name,
      };
    case 'date':
      return {
        type: 'date' as const,
        label: name,
      };
    case 'time':
      return {
        type: 'time' as const,
        label: name,
      };
    case 'datetime':
      return {
        type: 'datetime' as const,
        label: name,
      };
    case 'aggregateMap':
      return {
        type: 'aggregateMap' as const,
        label: name,
        aggregates: [
          {
            type: 'AggregateLoader',
            aggregateType: '',
          },
        ],
      };
    case 'streetView':
      return {
        type: 'streetView' as const,
        label: name,
      };
    case 'group':
      return {
        type: 'group' as const,
        label: name,
        template: {
          rows: 1,
          columns: 1,
          allowDynamicSizing: true,
          elements: {},
        },
      };
    default:
      return {
        type: 'text' as const,
        label: name,
      };
  }
};

export const generatePageItemName = (type: string, pageItemsList: string[]): string => {
  let counter = 1;

  const getFreeName = (): string => {
    const name = `${camelCaseToSpaceSeparated(type)}${counter}`;
    if (pageItemsList.includes(name)) {
      counter += 1;
      return getFreeName();
    }
    return name;
  };

  return getFreeName();
};
