import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult, DragStart } from 'react-beautiful-dnd';
import { V2PageTemplate } from '@terragotech/page-renderer';
import styled from 'styled-components';
import { createPageItem, generatePageItemName } from './defaultPageItems';
import { PageEditorCopyMenu } from './PageEditorCopyMenu';
import { PagePalette } from './PagePalette';
import { PageLayoutList } from './PageLayoutList';
import { cloneDeep, pick } from 'lodash';

interface PageLayoutEditorProps {
  pageDefinition: V2PageTemplate;
  fullPageDefinition: V2PageTemplate;
  setPageDefinition: React.Dispatch<React.SetStateAction<V2PageTemplate>>;
  isGroup: boolean;
  reset: number;
}

const elementLocationProps: Array<keyof V2PageTemplate['elements'][string]> = ['row', 'column', 'columnSpan'];

const getGridAreaId = (row: number, column: number) => `${row}-${column}`;
const getGridAreaRowColumn = (droppableId: string) => {
  const [row, column] = [...droppableId?.split('-').map(x => +x) ?? [undefined]] as const;
  return { row, column };
};

const emptySelectedItems: V2PageTemplate = {
  rows: 1,
  columns: 1,
  allowDynamicSizing: true,
  elements: {},
};

const defaultGridSpan = {
  rowSpan: 1,
  columnSpan: 1,
} as const;

export const PageLayoutEditor: React.FC<PageLayoutEditorProps> = ({
  pageDefinition,
  setPageDefinition,
  fullPageDefinition,
  isGroup,
  reset,
}) => {
  const [selectedItems, setSelectedItems] = useState<V2PageTemplate>(emptySelectedItems);
  const [lastPastedPageTemplate, setLastPastedPageTemplate] = useState<V2PageTemplate | null>(null);
  const [refresh, setRefresh] = useState(true);
  const [focusedItem, setFocusedItem] = useState('');
  const [dragSourceDroppableId, setDragSourceDroppableId] = useState<string | null>(null);

  const defaultGridSpans = useCallback(() => Object.entries(pageDefinition.elements).reduce((acc, [_, e]) => {
    const droppableId = getGridAreaId(e.row, e.column);
    const span = acc[droppableId] ?? defaultGridSpan;
    acc[droppableId] = {
      rowSpan: Math.max(span.rowSpan, /*e.rowSpan*/ 1),
      columnSpan: Math.max(span.columnSpan, e.columnSpan),
    };
    return acc;
  }, {} as Record<string, {rowSpan: number, columnSpan: number}>), [pageDefinition]);
  const [gridSpans, setGridSpans] = useState(defaultGridSpans());

  useEffect(()=>{
    if(!refresh){
      setRefresh(true);
    }
  }, [refresh])

  useEffect(() => {
    if (reset > 0) {
      setGridSpans(defaultGridSpans());
    }
  }, [reset]);

  const addPageItem = ({ destination, draggableId }: DropResult) => {
    if (!destination)
      return;

    const destinationLocation = getLocationInfo(destination.droppableId)

    const destinationHasElements = Object.entries(pageDefinition.elements)
      .some(([_, e]) => e.row === destinationLocation.row && e.column === destinationLocation.column);
    if (destinationHasElements)
      return;

    const addNewItemToPage = () => {
      const name = generatePageItemName(draggableId, Object.keys(page.elements));
      page.elements[name] = {
        ...page.elements[name],
        ...pick(destinationLocation, elementLocationProps),
        component: createPageItem(draggableId, name),
      };
    };

    const page = cloneDeep(pageDefinition);
    if (destination.droppableId !== 'palette') {
      addNewItemToPage();
      return setPageDefinition(page);
    }
    return setPageDefinition(page);
  };

  const moveItemFromPage = ({ draggableId, source, destination }: DropResult) => {
    if (!destination || destination.droppableId === source.droppableId)
      return;

    const reorderItemInPage = () => {
      const destinationLocation = getLocationInfo(destination.droppableId);
      const destinationElements = Object.entries(pageDefinition.elements)
        .filter(([_, e]) => e.row === destinationLocation.row && e.column === destinationLocation.column)
        .map(([key, _]) => key);

      // Move destination element(s) to source
      if (destinationElements.length) {
        destinationElements.forEach(key => {
          page.elements[key] = {
            ...page.elements[key],
            ...pick(getLocationInfo(source.droppableId), elementLocationProps),
          };
        });
      }

      // Move dragged element to destination
      page.elements[draggableId] = {
        ...page.elements[draggableId],
        ...pick(destinationLocation, elementLocationProps),
      };
    };

    let page = cloneDeep(pageDefinition);
    if (destination.droppableId !== 'palette') {
      reorderItemInPage();
      return setPageDefinition(page);
    }
  };

  const handleDragStart = (initial: DragStart) => {
    setDragSourceDroppableId(initial.source.droppableId);
  };

  const getLocationInfo = (droppableId: string) => {
    const { row, column } = getGridAreaRowColumn(droppableId);
    const { rowSpan, columnSpan } = gridSpans[droppableId] ?? defaultGridSpan;

    const pageElementEntries = Object.entries(pageDefinition.elements);

    const hasElements = pageElementEntries.some(([_, e]) => e.row === row && e.column === column);

    const { hideGridArea, extendRowSpanConflict, extendColumnSpanConflict } =
      [...Array(pageDefinition.rows).keys()].reduce((rAcc, gaRow) => 
        [...Array(pageDefinition.columns).keys()].reduce((cAcc, gaColumn) => {
          const gs = gridSpans[getGridAreaId(gaRow, gaColumn)] || defaultGridSpan;
          const hideGridArea = cAcc.hideGridArea || (
            (gaRow !== row || gaColumn !== column)
            && (gaRow === row || gaRow < row && gaRow + gs.rowSpan > row)
            && (gaColumn === column || gaColumn < column && gaColumn + gs.columnSpan > column)
          );
          const extendRowSpanConflict = cAcc.extendRowSpanConflict || (
            gaRow === row + rowSpan && gaColumn >= column && gaColumn < column + columnSpan
            && (gs.columnSpan > 1 || gs.rowSpan > 1 || pageElementEntries.some(([_, e]) => e.row === gaRow && e.column === gaColumn))
          );
          const extendColumnSpanConflict = cAcc.extendColumnSpanConflict || (
            gaColumn === column + columnSpan && gaRow >= row && gaRow < row + rowSpan
            && (gs.columnSpan > 1 || gs.rowSpan > 1 || pageElementEntries.some(([_, e]) => e.row === gaRow && e.column === gaColumn))
          );
          return { hideGridArea, extendRowSpanConflict, extendColumnSpanConflict };
        }, rAcc),
        { hideGridArea: false, extendRowSpanConflict: false, extendColumnSpanConflict: false }
      );

    return {
      row,
      column,
      rowSpan,
      columnSpan,
      canShrinkRowSpan: rowSpan > 1,
      canExtendRowSpan: !extendRowSpanConflict && row + rowSpan < pageDefinition.rows,
      canShrinkColumnSpan: columnSpan > 1,
      canExtendColumnSpan: !extendColumnSpanConflict && column + columnSpan < pageDefinition.columns,
      hasElements,
      hideGridArea,
    };
  }

  const handleDragEnd = (result: DropResult) => {
    const { reason, source, destination } = result;

    if (reason !== 'DROP' || !destination || destination.droppableId === source.droppableId)
      return;

    switch (source.droppableId) {
      case 'palette':
        addPageItem(result);
        break;
      default:
        moveItemFromPage(result);
        break;
    }

    setDragSourceDroppableId(null);
  };

  const setGridSpan = (droppableId: string, newSpan: Partial<typeof gridSpans[string]>) => {
    if (!newSpan.columnSpan && !newSpan.rowSpan)
      return;

    setGridSpans((spans) => {
      const spansCopy = cloneDeep(spans);
      spansCopy[droppableId] = {
        ...spans[droppableId] ?? defaultGridSpan,
        ...newSpan,
      };
      return spansCopy;
    });
  };

  useEffect(() => {
    const { rows, columns } = pageDefinition;
    setGridSpans((val) => {
      const spans = cloneDeep(val);
      Object.entries(gridSpans)
        .forEach(([key, { rowSpan, columnSpan }]) => {
          const { row, column } = getGridAreaRowColumn(key);
          if (row + rowSpan > rows)
            spans[key].rowSpan = Math.max(1, rows - row);
          if (column + columnSpan > columns)
            spans[key].columnSpan = Math.max(1, columns - column);
        });
      return spans;
    });
  }, [pageDefinition.rows, pageDefinition.columns, setGridSpans]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageDefinition(val => {
      const page = cloneDeep(val);
      Object.entries(pageDefinition.elements)
        .forEach(([key, e]) => {
          page.elements[key] = {
            ...page.elements[key],
            ...pick(gridSpans[getGridAreaId(e.row, e.column)], elementLocationProps),
          };
        });
      return page;
    });
  }, [gridSpans, setPageDefinition]);  // eslint-disable-line react-hooks/exhaustive-deps

  const shrinkColumnSpan = (droppableId: string) => {
    const { columnSpan, canShrinkColumnSpan } = getLocationInfo(droppableId);
    if (!canShrinkColumnSpan)
      return;

    const newSpans = {columnSpan: columnSpan - 1};
    setGridSpan(droppableId, newSpans);
  };

  const extendColumnSpan = (droppableId: string) => {
    const { columnSpan, canExtendColumnSpan } = getLocationInfo(droppableId);
    if (!canExtendColumnSpan)
      return;

    setGridSpan(droppableId, {columnSpan: columnSpan + 1});
  };

  return refresh ? (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <FlexRowContainer>
        {/* @ts-ignore */}
        <PageContainer
          rows={pageDefinition.rows}
          columns={pageDefinition.columns}
        >
          {pageDefinition.rows && [...Array(pageDefinition.rows).keys()].map(r => (
            <React.Fragment key={r}>
              {pageDefinition.columns && [...Array(pageDefinition.columns).keys()].map(c => {
                const droppableId = getGridAreaId(r, c);
                const { rowSpan, columnSpan, hasElements, hideGridArea, canShrinkColumnSpan, canExtendColumnSpan } = getLocationInfo(droppableId);
                return (
                  <Droppable key={c}
                    droppableId={droppableId}
                    isDropDisabled={hasElements && dragSourceDroppableId === 'palette'}
                  >
                    {(droppableProvided, snapshot) => (
                      <PageInner
                        {...droppableProvided.droppableProps}
                        ref={droppableProvided.innerRef}
                        row={r}
                        column={c}
                        rowSpan={rowSpan}
                        columnSpan={columnSpan}
                        hidden={hideGridArea}
                      >
                        <PageLayoutList
                          pageDefinition={pageDefinition}
                          fullPageDefinition={fullPageDefinition}
                          setPageDefinition={setPageDefinition}
                          selectedItems={selectedItems}
                          setSelectedItems={setSelectedItems}
                          lastPastedPageTemplate={lastPastedPageTemplate}
                          setRefresh={setRefresh}
                          groupDragging={false}
                          focusedItem={focusedItem}
                          setFocusedItem={setFocusedItem}
                          row={r}
                          column={c}
                        />
                        <SpanButtonContainer>
                          <SpanButton onClick={() => shrinkColumnSpan(droppableId)} hidden={!canShrinkColumnSpan}>&lt; Shrink</SpanButton>
                          <span hidden={!canShrinkColumnSpan || !canExtendColumnSpan}>&nbsp;|&nbsp;</span>
                          <SpanButton onClick={() => extendColumnSpan(droppableId)} hidden={!canExtendColumnSpan}>Extend &gt;</SpanButton>
                        </SpanButtonContainer>
                        {droppableProvided.placeholder}
                      </PageInner>
                    )}
                  </Droppable>
                )
              })}
            </React.Fragment>
          ))}
        </PageContainer>
        {/* @ts-ignore */}
        <Palette>
          <Droppable droppableId="palette" isDropDisabled={true}>
            {(
              droppableProvided //@ts-ignore
            ) => (
              <PaletteInner {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                <PagePalette isGroup={isGroup} />
                <PageEditorCopyMenu
                  pageDefinition={pageDefinition}
                  setPageDefinition={setPageDefinition}
                  selectedItems={selectedItems}
                  lastPastedPageTemplate={lastPastedPageTemplate}
                  setLastPastedPageTemplate={setLastPastedPageTemplate}
                />
                {droppableProvided.placeholder}
              </PaletteInner>
            )}
          </Droppable>
        </Palette>
      </FlexRowContainer>
    </DragDropContext>
  ) : null;
};

const PageContainer = styled.div<{rows: number, columns: number}>(({ style, rows, columns }) => ({
  flex: 1,
  display: 'grid',
  gridAutoRows: 'minmax(0, 1em)',
  gap: '0.2em 0.2em',
  gridTemplateRows: `repeat(${rows}, minmax(0, 1fr))`,
  gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  alignItems: 'normal',
  background: '#eeeeee',
  ...style,
}));

const Palette = styled.div`
  width: 180px;
  flex-direction: column;
  align-items: center;
  background: #eeeeee;
`;

const FlexRowContainer = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
`;

const PageInner = styled.div<{
  row: number,
  rowSpan: number,
  column: number,
  columnSpan: number,
}>`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  grid-row: ${props => props.row + 1} / ${props => props.row + 1 + props.rowSpan};
  grid-column: ${props => props.column + 1} / ${props => props.column + 1 + props.columnSpan};
  border: 1px solid;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  align-content: normal;
`;

const PaletteInner = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  position: sticky;
  top: 65px;
`;

const SpanButtonContainer = styled.small`
  align-self: center;
`;
const SpanButton = styled.span`
  cursor: pointer;
`;
