"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFieldsToIndex = exports.mapAggregateTypeToAggregateTable = exports.getPropertyDefinitions = exports.hasProperties = exports.findAggregateConfig = exports.getFieldsWithRelationshipType = exports.hasAnyPropertyToIncludeInFullText = exports.extractPropertiesToIncludeInFullText = void 0;
const gen5_config_lib_1 = require("@terragotech/gen5-config-lib");
const extractPropertiesToIncludeInFullText = (aggregate) => {
    return Object.keys(aggregate.properties).filter(val => {
        return aggregate.properties[val].includeInFullText && !gen5_config_lib_1.isRelationshipType(aggregate.properties[val]);
    });
};
exports.extractPropertiesToIncludeInFullText = extractPropertiesToIncludeInFullText;
const hasAnyPropertyToIncludeInFullText = (aggregate) => {
    return exports.extractPropertiesToIncludeInFullText(aggregate).length != 0;
};
exports.hasAnyPropertyToIncludeInFullText = hasAnyPropertyToIncludeInFullText;
const getFieldsWithRelationshipType = (aggregate) => {
    return Object.keys(aggregate.properties).filter(propKey => {
        const prop = aggregate.properties[propKey];
        return gen5_config_lib_1.isRelationshipType(prop) && prop.relation === 'ONE-TO-ONE';
    });
};
exports.getFieldsWithRelationshipType = getFieldsWithRelationshipType;
const findAggregateConfig = (aggregateConfigs, type) => {
    return aggregateConfigs.find(agg => agg.typeName === type);
};
exports.findAggregateConfig = findAggregateConfig;
const hasProperties = (aggregate, properties) => {
    properties.forEach(property => {
        if (!Object.keys(aggregate.properties).includes(property))
            return false;
    });
    return true;
};
exports.hasProperties = hasProperties;
const getPropertyDefinitions = (aggregateDefinition) => Object.keys(aggregateDefinition.properties).map((prop) => {
    const relationProps = aggregateDefinition.properties[prop];
    return {
        field: prop,
        label: relationProps.label,
        uiType: 'uiType' in relationProps ? relationProps.uiType || relationProps.type : relationProps.type,
        type: relationProps.type,
        validOptions: relationProps.validOptions,
        isRelationship: 'relation' in relationProps,
        isEditable: !!relationProps.editable,
        foreignColumn: 'foreignColumn' in relationProps ? relationProps.foreignColumn : null,
        isFilterable: relationProps.filterable,
        relationshipType: 'relation' in relationProps ? relationProps.relation : null,
    };
});
exports.getPropertyDefinitions = getPropertyDefinitions;
const mapAggregateTypeToAggregateTable = (aggregateConfigs, type) => {
    const aggregateConfig = exports.findAggregateConfig(aggregateConfigs, type);
    return aggregateConfig.objectStore.table;
};
exports.mapAggregateTypeToAggregateTable = mapAggregateTypeToAggregateTable;
const getFieldsToIndex = (aggregate) => {
    return Object.keys(aggregate.properties).filter(propKey => {
        const prop = aggregate.properties[propKey];
        return (gen5_config_lib_1.isRelationshipType(prop) && prop.relation === 'ONE-TO-ONE') || (!gen5_config_lib_1.isRelationshipType(prop) && prop.indexed);
    });
};
exports.getFieldsToIndex = getFieldsToIndex;
