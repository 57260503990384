import React, { useContext, useState } from 'react';
import { Paper, Button, Tabs, Tab } from '@material-ui/core';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { FormHelperText } from '@material-ui/core';
import { SelectInput } from '../../../components/FormElements';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
interface MeasurementType {
  label: string;
  value: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers';
}
interface PrecisionType {
  label: string;
  value: 'ones' | 'tenths' | 'hundredths' | 'thousandths';
}

const measurementLabelsAndValues: MeasurementType[] = [
  { label: 'Feet (ft)', value: 'feet' },
  { label: 'Yards (yd)', value: 'yards' },
  { label: 'Miles (mi)', value: 'miles' },
  { label: 'Meters (m)', value: 'meters' },
  { label: 'Kilometers (km)', value: 'kilometers' },
];
const precisionLabelsAndValues: PrecisionType[] = [
  { label: 'Ones (123)', value: 'ones' },
  { label: 'Tenths (123.1)', value: 'tenths' },
  { label: 'Hundredths (123.12)', value: 'hundredths' },
  { label: 'Thousandths (123.123)', value: 'thousandths' },
];

export const Geographic: React.FC = () => {
  const {
    setGeographicMobile,
    getGeographicMobile,
    getGeographicWeb,
    setGeographicWeb,
  } = useContext(ConfigContext);
  const [mandatoryClusteringSwitch, setMandatoryClusteringSwitch] = useState<boolean>(
    getGeographicMobile().mapSettings?.isMandatoryClusteringEnabled ?? true
  );

  const [panMapSwitch, setPanMapSwitch] = useState<boolean>(
    (getGeographicMobile().enabledFeatures?.refocusToCurrentLocationAfterDirections as boolean) ??
      true
  );

  const [selectedTab, setSelectedTab] = React.useState(0);

  const geographicWeb = getGeographicWeb().geographic;

  const initialMeasurementLabel =
    measurementLabelsAndValues.find((x) => x.value === geographicWeb?.polylineUnitOfMeasurement)
      ?.label ?? measurementLabelsAndValues[0].label;
  const initialPrecisionLabel =
    precisionLabelsAndValues.find((x) => x.value === geographicWeb?.polylineRoundingPrecision)
      ?.label ?? precisionLabelsAndValues[0].label;

  const [measurementLabel, setMeasurementLabel] = useState<string>(initialMeasurementLabel);
  const [precisionLabel, setPrecisionLabel] = useState<string>(initialPrecisionLabel);

  const onSave = (): void => {
    setGeographicMobile(mandatoryClusteringSwitch, panMapSwitch);
    setGeographicWeb(
      measurementLabelsAndValues.find((x) => x.label === measurementLabel)?.value,
      precisionLabelsAndValues.find((x) => x.label === precisionLabel)?.value
    );
    successMsg('Geographic has been saved successfully');
  };

  const onSwitchChange = (_: React.ChangeEvent<HTMLInputElement>) => {
    setMandatoryClusteringSwitch(!mandatoryClusteringSwitch);
  };

  const onPanMapChange = (_: React.ChangeEvent<HTMLInputElement>) => {
    setPanMapSwitch(!panMapSwitch);
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <>{children} </>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      'aria-controls': `tabpanel-${index}`,
    };
  };

  const onTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <HeaderContainer>
        <Typography variant="h6">Geographic</Typography>
        <Button variant="contained" size="small" color="primary" onClick={onSave}>
          Save
        </Button>
      </HeaderContainer>
      <BodyContainer>
        <Tabs
          value={selectedTab}
          onChange={onTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Mobile" {...a11yProps(0)} style={{ width: 600 }} />
          {/* <Tab label="Web" {...a11yProps(1)} /> */}
        </Tabs>

        <TabPanel value={selectedTab} index={0}>
          <div style={{ margin: '12px 24px' }}>
            <FormControlLabel
              style={{ marginTop: 5 }}
              control={
                <Switch
                  checked={mandatoryClusteringSwitch}
                  onChange={onSwitchChange}
                  color="primary"
                />
              }
              label="Force clustering of overlapping map symbols"
            />
            <FormHelperText>
              Enabling this will show clusters on overlapping map symbols, even if the user has
              clustering disabled
            </FormHelperText>

            <FormControlLabel
              style={{ marginTop: 5 }}
              control={<Switch checked={panMapSwitch} onChange={onPanMapChange} color="primary" />}
              label="Pan map after directions"
            />
            <FormHelperText>
              When the user leave the app and returns,the map bounds will be updated to the users
              current location
            </FormHelperText>
          </div>
        </TabPanel>
      </BodyContainer>
      <BodyContainer>
        <Tabs
          value={0}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Web" {...a11yProps(0)} style={{ width: 600 }} />
        </Tabs>
        <TabPanel value={selectedTab} index={0}>
          <div style={{ margin: '12px 24px' }}>
            <div style={{ fontSize: 15, fontWeight: 'bold', paddingBottom: '10px' }}>
              Polyline Measurement settings
            </div>
            <div style={{ display: 'flex', width: 450 }}>
              <SelectInput
                style={{ width: 200 }}
                title="Unit of Measurement"
                value={measurementLabel}
                onChange={(label: string) => setMeasurementLabel(label)}
                options={measurementLabelsAndValues.map((x) => x.label)}
              />
              <SelectInput
                style={{ width: 200 }}
                title="Rounding Precision"
                value={precisionLabel}
                onChange={(label: string) => setPrecisionLabel(label)}
                options={precisionLabelsAndValues.map((x) => x.label)}
              />
            </div>
          </div>
        </TabPanel>
      </BodyContainer>
    </Container>
  );
};

const Container = styled('div')({
  width: '100%',
  flex: 1,
});

const HeaderContainer = styled(Paper)({
  padding: '20px 20px',
  margin: 5,
  flex: 1,
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
});

const BodyContainer = styled(Paper)({
  margin: '15px 5px',
  flex: 9,
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
});
