import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import { ConfigContext } from '../../context/ConfigContext';
import { useHistory } from 'react-router-dom';
import { migrateInputJson } from '../../pages/aggregates/utils/MigrationUtils';

function isResultAString(result: string | ArrayBuffer | null | undefined): result is string {
  return !!result && typeof result === 'string';
}

export const ConfigUpload: React.FC = () => {
  const history = useHistory();
  const { setConfig, configName, setConfigName } = useContext(ConfigContext);

  const onButtonClick = (): void => {
    inputFile.current?.click();
  };
  const inputFile = useRef<HTMLInputElement>(null);

  const loadJson = (e: React.ChangeEvent<HTMLInputElement>): void => {
    history.push('/');
    if (!e) {
      alert('Failed to load file');
      return;
    }
    const f = e.target?.files?.[0];
    if (f) {
      const fr = new FileReader();
      fr.onload = (ev) => {
        try {
          setConfigName(f.name);
          const x = ev.target?.result;
          if (isResultAString(x)) {
            const fileJson = JSON.parse(x);
            setConfig(migrateInputJson(fileJson));
          }
        } catch (ex) {
          alert('Failed to parse .JSON file');
        }
      };
      fr.readAsText(f);
    }
  };

  return (
    <UploadButtonContainer>
      {configName && <LoadedConfigLabel>Config: {configName}</LoadedConfigLabel>}
      <input
        type="file"
        accept="application/json, .json"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        onChange={(e) => loadJson(e)}
      />
      <UploadButton
        variant="contained"
        onClick={onButtonClick}
        style={{ color: 'black' }}
        startIcon={<CloudUploadIcon />}
      >
        IMPORT CONFIG .JSON
      </UploadButton>
    </UploadButtonContainer>
  );
};

const UploadButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 10px 0;
`;

const LoadedConfigLabel = styled.p`
  color: white;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 90%;
`;

const UploadButton = styled(Button)`
  && {
    width: 222px;
  }
`;
