import React, { useCallback, useContext, useMemo } from 'react';
import { Paper, Divider, Button } from '@material-ui/core';
import styled from 'styled-components';
import { EventMapDiagram, useDataMapDiagram } from '../../../map-editor/src';
import { convertV2FormTemplateToJsonSchema } from '../utils/V2FormTemplateToJsonSchema';
import { sample } from '../utils/sampleForm';
import { FullScreen } from '../../../components/FullScreen';
import { ConfigContext } from '../../../context/ConfigContext';
import { successMsg } from '../../../components/SnackbarUtilsConfigurator';
import { useParams, Prompt } from 'react-router-dom';
import { getAggregateIndex } from '../../../utils/navigationUtils';
import { useAggregateAPI } from '../../../context/fakeAPIHooks/useAggregateAPI';
import { useConfirm } from 'material-ui-confirm';
import { useSchemaLookup } from '../../../utils/useSchemaLookup';
import { propertiesToSchema } from '../utils/PropertiesToSchemaConverter';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { useFormDialog } from '../../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../../components/FormDialog/MapperShortCutInfoDialog';

export const EventMapper: React.FC = () => {
  const { config, getEventSchema } = useContext(ConfigContext);
  const AggregateAPI = useAggregateAPI();

  //For delete confirmation
  const confirm = useConfirm();
  const formDialog = useFormDialog();

  const { aggregate: aggrName, event: eventName, eventVersion } = useParams() as {
    aggregate: string;
    event: string;
    eventVersion: string;
  };
  const aggrIndex = getAggregateIndex(config, aggrName);

  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };

  const handleSaveRequest = async () => {
    const thing = model?.getMapDefinition();
    if (thing) {
      const { error } = await AggregateAPI.updateAggregateMap(
        aggrIndex,
        eventName,
        +eventVersion,
        thing
      );
      if (error) return;
      successMsg('Aggregate map has been saved');
    }
  };

  const localSchemas = useMemo(() => {
    return {
      EVENT: {
        schema:
          getEventSchema(aggrIndex, eventName, +eventVersion) ||
          convertV2FormTemplateToJsonSchema(sample),
        schemaLabel: eventName,
      },
      STATE: {
        schema: propertiesToSchema(config.aggregates[aggrIndex].properties || {}),
        schemaLabel: 'Properties',
      },
    };
  }, [aggrIndex, eventName, eventVersion, config.aggregates, getEventSchema]);
  const schemaLookup = useSchemaLookup({
    currentAggregateType: aggrName,
    localSchemas: localSchemas,
  });
  const { model, engine, checkModelHasChanged } = useDataMapDiagram({
    //outputObject: config.aggregates[aggrIndex].properties || {},
    //sourceObjects,
    scenario: 'EVENT_AGGREGATE_MAP',
    schemaLookup: schemaLookup,
    dataMap:
      config.aggregates[aggrIndex].events?.[eventName]?.versions[+eventVersion]?.aggregateMap,
  });

  const ifShowMessageBeforeRedirect = useCallback(() => {
    return checkModelHasChanged() ? 'The form has not been saved, do you want to redirect?' : true;
  }, [checkModelHasChanged]);

  return (
    <EventMapperContainer>
      <CardTitle style={{ padding: '10px 20px 0px' }}>Aggregate map</CardTitle>
      <GroupDivider />
      <FullScreen>
        <EventMapDiagram
          model={model}
          engine={engine}
          confirm={confirm}
          mapScenario={'EVENT_AGGREGATE_MAP'}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveRequest}
          style={{ position: 'absolute', right: 20, bottom: 20 }}
        >
          Save
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: 110,
            bottom: 20,
            color: '#ffffff',
            backgroundColor: 'black',
          }}
          onClick={handleShortCutInfo}
          variant="contained"
          startIcon={<KeyboardIcon />}
        >
          SHORTCUTS
        </Button>
        <Prompt message={ifShowMessageBeforeRedirect} />
      </FullScreen>
    </EventMapperContainer>
  );
};

const EventMapperContainer = styled(Paper)`
  margin: 5px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 92px);
`;

const CardTitle = styled.p`
  margin-top: 10px;
  word-break: break-word;
`;

const GroupDivider = styled(Divider)`
  && {
    background-color: rgb(220, 220, 220);
  }
`;
