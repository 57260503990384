import React from 'react';
import styled from 'styled-components';
import { isEqual, cloneDeep } from 'lodash';
import {
  V2GroupComponentDef,
  V2PageComponentDef,
  V2PageTemplate,
} from '@terragotech/page-renderer';
import { blue } from '@material-ui/core/colors';
import { useConfirm } from 'material-ui-confirm';
import { usePageDialog } from '../../../../components/PageDialog/PageDialogService';
import { successMsg } from '../../../../components/SnackbarUtilsConfigurator';
import { useMapperRefChanger } from '../../../../utils/useMapperRefChanger';
import { getAggregateIndex } from '../../../../utils/navigationUtils';
import { useConfig } from '../../../../context/ConfigContext';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { GroupEditForm } from '../../../../components/PageDialog/GroupEditForm';
import { TextInputEditForm } from '../../../../components/PageDialog/TextInputEditForm';
import { PageContextProvider } from '../../../../components/PageDialog/contexts/PageContext';
import { AggregateContextProvider } from '../../../../components/FormDialog/contexts/AggregateContext';
import { controlMap } from './MobileTheme';
import { Draggable } from 'react-beautiful-dnd';
import { MapEditForm } from '../../../../components/PageDialog/MapEditForm';
import { StreetViewEditForm } from '../../../../components/PageDialog/StreetViewEditForm';

interface PageLayoutListProps {
  pageDefinition: V2PageTemplate;
  fullPageDefinition: V2PageTemplate;
  setPageDefinition: (val: V2PageTemplate) => void;
  selectedItems: V2PageTemplate;
  setSelectedItems: (val: V2PageTemplate) => void;
  lastPastedPageTemplate: V2PageTemplate | null;
  setRefresh: (boolean: boolean) => void;
  groupDragging: boolean;
  focusedItem: string;
  setFocusedItem: (val: string) => void;
  row: number;
  column: number;
}

export type V2PageComponentDefWithName = V2PageComponentDef & { name: string; droppableId: string };

export const PageLayoutList: React.FC<PageLayoutListProps> = ({
  pageDefinition,
  setPageDefinition,
  selectedItems,
  setSelectedItems,
  lastPastedPageTemplate,
  setRefresh,
  groupDragging,
  fullPageDefinition,
  focusedItem,
  setFocusedItem,
  row,
  column,
}) => {
  const { config } = useConfig();
  const aggrName = fullPageDefinition.aggregateType;
  const aggrIndex = getAggregateIndex(config, aggrName);
  const currentAggregate = config?.aggregates?.[aggrIndex];

  const confirm = useConfirm();
  const pageDialog = usePageDialog();
  const mapperRefChanger = useMapperRefChanger();

  const handleSelectedItem = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    pageComponentName: string,
    group?: string
  ) => {
    const fromDefinitionCopy = cloneDeep(pageDefinition);

    const addItemToPage = () => {
      const copySelectedItems = cloneDeep(selectedItems);
      copySelectedItems.elements[pageComponentName] = {
        row: 1,
        column: 1,
        columnSpan: 1,
        component: pageComponentValue,
      };
      return copySelectedItems;
    };

    const removeItemFromPage = (declaredItemToRemove?: string) => {
      const pageItemNameToRemove = declaredItemToRemove || pageComponentName;
      const copySelectedItems = cloneDeep(selectedItems);
      delete copySelectedItems.elements[pageItemNameToRemove];
      return copySelectedItems;
    };

    let pageComponentValue: V2PageComponentDef;

    pageComponentValue = fromDefinitionCopy.elements[pageComponentName]
      .component as V2PageComponentDef;
    event.stopPropagation();

    // In that case, event.ctrlKey does the trick.
    if (event.ctrlKey || event.metaKey) {
      if (!selectedItems.elements.hasOwnProperty(pageComponentName)) {
        setSelectedItems(addItemToPage());
      } else {
        setSelectedItems(removeItemFromPage());
      }
    } else if (!isEqual(emptySelectedItems, selectedItems)) {
      setSelectedItems(emptySelectedItems);
    }
    setFocusedItem(`${group || ''}${pageComponentName}`);
  };

  const editItem = async (
    component: V2PageComponentDef,
    name: string,
    droppableId: string,
    index: number
  ) => {
    const removeItemFromPage = () => {
      delete page.elements[name];
    };
    const addModifiedItemToPage = () => {
      //@ts-ignore
      delete value.name;
      page.elements[newName] = { row, column, columnSpan, component: value };
    };

    let page = cloneDeep(pageDefinition);
    const { row, column, columnSpan } = page.elements[name];
    const item: V2PageComponentDefWithName = { ...cloneDeep(component), name, droppableId };
    const value = await pageDialog<typeof TextInputEditForm>(
      // I made a questionable choice to move these values into context, without realizing the dialog is in a separate branch of the tree
      (props) => (
        <AggregateContextProvider aggregateConfig={currentAggregate}>
          <PageContextProvider pageDefinition={fullPageDefinition}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {getPageComponent(item, props)}
            </div>
          </PageContextProvider>
        </AggregateContextProvider>
      ),
      true
    );
    const { name: newName } = value;
    successMsg(`Page element "${name}" has been successfully edited`);
    if (droppableId === 'page') {
      removeItemFromPage();
      addModifiedItemToPage();
      page = mapperRefChanger.renameFormReferences(page, name, newName);
      setPageDefinition(page);
      try {
        setRefresh(false);
      } catch (error) {
        console.error(error);
      }
      return;
    }
  };

  const deleteItem = async (id: string, droppableId: string, index: number) => {
    let page = cloneDeep(pageDefinition);
    const removeItemFromPage = () => {
      delete page.elements[id];
    };

    await confirm({
      description: `Do you want to delete "${id}" page element?`,
      confirmationText: 'Delete',
    });
    successMsg(`Page element "${id}" has been successfully deleted`);
    if (droppableId === 'page') {
      removeItemFromPage();
      page = mapperRefChanger.removeFormReferences(page, id);
      setPageDefinition(page);
      return;
    }
  };

  return (
    <>
      {Object.entries(pageDefinition.elements)
        .filter(([_, e]) => e.row === row && e.column === column)
        .map(([name, _], index) => {
          const component = pageDefinition.elements[name].component as V2GroupComponentDef;
          const Control = component && controlMap[component.type];
          if (Control) {
            return (
              <Draggable key={name} draggableId={name} index={index}>
                {(provided) => (
                  <PanelItem
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isFocused={focusedItem === name}
                    isSelected={isEqual(
                      selectedItems.elements[name],
                      pageDefinition.elements[name]
                    )}
                    hasMargin={component.type === 'group'}
                    onClick={(e) => handleSelectedItem(e, name)}
                  >
                    <Control
                      controlDefinition={component}
                      name={name}
                      isDropDisabled={groupDragging}
                      focusedItem={focusedItem}
                      selectedItems={selectedItems}
                      setSelectedItems={handleSelectedItem}
                      editItem={editItem}
                      deleteItem={deleteItem}
                      pasted={Object.keys(lastPastedPageTemplate?.elements || {}).includes(name)}
                      lastPastedPageGroup={
                        lastPastedPageTemplate?.elements[name].component as V2GroupComponentDef
                      }
                    />
                    {focusedItem === name && (
                      <ItemButtonsContainer isGroup={component.type === 'group'}>
                        <IconButton
                          style={{ color: blue[300], padding: 3 }}
                          component="span"
                          onClick={() => {
                            editItem(component, name, 'page', index);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          style={{ color: blue[300], padding: 3, marginRight: 3 }}
                          component="span"
                          onClick={() => {
                            deleteItem(name, 'page', index);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ItemButtonsContainer>
                    )}
                  </PanelItem>
                )}
              </Draggable>
            );
          } else {
            console.log(`Couldn't find component: ${pageDefinition.elements[name]}`);
          }
          return null;
        })}
    </>
  );
};

const getPageComponent = (
  item: V2PageComponentDefWithName,
  props: React.PropsWithChildren<{
    onClose: () => void;
    onSubmit: <T>(result: T) => void;
  }>
) => {
  switch (item.type) {
    case 'group':
      return <GroupEditForm component={item} {...props} />;
    case 'aggregateMap':
      return <MapEditForm component={item} {...props} />;
    case 'streetView':
      return <StreetViewEditForm component={item} {...props} />;
    case 'text':
    case 'number':
      return <TextInputEditForm component={item} {...props} />;
    case 'date':
    case 'time':
    case 'datetime':
      return <TextInputEditForm component={item} {...props} />;
    default:
      return null;
  }
};

const emptySelectedItems = {
  rows: 1,
  columns: 1,
  allowDynamicSizing: true,
  elements: {},
};

const PanelItem = styled.div<{ isFocused?: boolean; hasMargin?: boolean; isSelected?: boolean }>`
  position: relative;
  background: #ffffff;
  color: #aaaaaa;
  border-bottom: solid 1px #eeeeee;
  box-shadow: 0px 3px 3px rgba(69, 81, 87, 0.2);
  background: ${(props) => props.isFocused && '#F4FAFE'};
  margin-bottom: ${(props) => props.hasMargin && '8px'};
  margin-top: ${(props) => props.hasMargin && '8px'};
  border: ${(props) => props.isSelected && `1px solid ${blue[300]}`};
  border-radius: ${(props) => props.isSelected && `3px`};
  &:focus {
    outline: none;
  }
`;

const ItemButtonsContainer = styled.div<{ isGroup?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  margin: ${(props) => (props.isGroup ? 0 : 3)};
`;
