import React, { useCallback } from 'react';
import { Dialog, Paper, Button, IconButton } from '@material-ui/core';
import styled from 'styled-components';
import {
  mapScenarios,
  MapScenarioType,
  NodeMapDefinition,
} from '@terragotech/gen5-datamapping-lib';
import { EventMapDiagram, useDataMapDiagram } from '../../map-editor/src';
import CloseIcon from '@material-ui/icons/Close';
import { successMsg } from '../SnackbarUtilsConfigurator';
import { useConfirm } from 'material-ui-confirm';
import { LocalSchemaDefinition, useSchemaLookup } from '../../utils/useSchemaLookup';
import KeyboardIcon from '@material-ui/icons/Keyboard';
import { useFormDialog } from '../../components/FormDialog/FormDialogService';
import { MapperShortCutInfoDialog } from '../../components/FormDialog/MapperShortCutInfoDialog';

export interface DataMapperDialogProps {
  open: boolean;
  onClose: () => void;
  datamap?: NodeMapDefinition | undefined;
  setDatamap: (newMap?: NodeMapDefinition) => void;
  mapScenario: keyof MapScenarioType;
  localSchemaDefinitions?: LocalSchemaDefinition;
}

function DataMapperDialog({
  open,
  datamap,
  setDatamap,
  onClose,
  mapScenario,
  localSchemaDefinitions,
}: DataMapperDialogProps) {
  return open ? (
    <DataMapperDialogWrapped
      open={open}
      datamap={datamap}
      setDatamap={setDatamap}
      onClose={onClose}
      mapScenario={mapScenario}
      localSchemaDefinitions={localSchemaDefinitions}
    />
  ) : (
    <></>
  );
}
function DataMapperDialogWrapped({
  open,
  datamap,
  setDatamap,
  onClose,
  mapScenario,
  localSchemaDefinitions,
}: DataMapperDialogProps) {
  const schemaLookup = useSchemaLookup({
    localSchemas: localSchemaDefinitions,
  });
  const { model, engine } = useDataMapDiagram({
    dataMap: datamap || (mapScenario ? mapScenarios[mapScenario].defaultNodeMap : undefined),
    schemaLookup: schemaLookup,
    scenario: mapScenario,
  });

  //For delete confirmation
  const confirm = useConfirm();

  const handleSaveRequest = useCallback(() => {
    if (model?.getMapDefinition) {
      setDatamap(model.getMapDefinition() as NodeMapDefinition);
      successMsg('Conditional mapping successfully saved');
    }
    onClose();
    // eslint-disable-next-line
  }, [setDatamap, onClose, model]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const formDialog = useFormDialog();
  const handleShortCutInfo = () => {
    formDialog<typeof MapperShortCutInfoDialog>((props) => <MapperShortCutInfoDialog {...props} />);
  };

  return (
    <Dialog fullWidth maxWidth={false} open={open} onClose={handleCancel}>
      <EventMapperContainer>
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', left: 10, top: 10, zIndex: 1350 }}
          title="Exit full-screen mode"
        >
          <CloseIcon style={{ color: 'white' }} />
        </IconButton>
        <EventMapDiagram
          engine={engine}
          model={model}
          confirm={confirm}
          mapScenario={mapScenario || 'BUTTON_STATE'}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveRequest}
          style={{ position: 'absolute', right: 20, bottom: 20 }}
        >
          Save
        </Button>
        <Button
          style={{
            position: 'absolute',
            right: 110,
            bottom: 20,
            color: '#ffffff',
            backgroundColor: 'black',
          }}
          onClick={handleShortCutInfo}
          variant="contained"
          startIcon={<KeyboardIcon />}
        >
          SHORTCUTS
        </Button>
      </EventMapperContainer>
    </Dialog>
  );
}
const EventMapperContainer = styled(Paper)`
  margin: 5px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100vh - 92px);
  width: calc(100% - 10px);
`;
export default DataMapperDialog;
