import React, { createContext, useState, useContext, useEffect } from 'react';
import {
  Gen5Config,
  AggregateConfig,
  EventDefinition,
  CommandDefinition,
  CommandVersionDefinition,
  PropertyCollection,
  MobileUIConfig,
  WebUIConfig,
  PagesConfig,
} from '@terragotech/gen5-config-lib';
import { FunctionDefinition } from '@terragotech/gen5-datamapping-lib';
import { configExample } from '../utils/configExample';
import { cloneDeep } from 'lodash';
import {
  CardDefinition,
  UIConfigType,
  Events,
  EventVersion,
  MobileAggrUICustomization,
  WebAggrUICustomization,
  AggregateUICustomizations,
} from '../utils/types';
import { Commands, FabActions, ActionButtons } from '../utils/types';
import { JSONSchema6 } from 'json-schema';
import { ActionButtonType } from '../components/ActionButtonEditor/ActionButtonEditor';
import { extractActionButtons, setExtractedActionButtons } from './contextUtils';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { generateMapServiceAggregateData, removeMapServiceAggregateData } from 'utils/aggregatesGenerators';

interface Props {
  children: React.ReactNode;
}

export interface ContextProps {
  config: Gen5Config;
  getConfig(): Gen5Config;
  setConfig(config: Gen5Config): void;

  getAggregates(): AggregateConfig[];
  setAggregates(aggregates: AggregateConfig[]): void;

  getFunctions(): Record<string, FunctionDefinition>;
  setFunctions(functions: Record<string, FunctionDefinition>): void;

  getCustomPages(): PagesConfig;
  setCustomPages(customPages: PagesConfig): void;

  setLegalDisclaimer(disclaimerTxt: string): void;
  getLegalDisclaimer(): string | '';

  setMapOptions(options: {nearMaps?: boolean, mapServices?: boolean}): void;
  getNearMaps(): boolean | false;
  getMapServices(): boolean | false;

  setGeographicMobile(
    isMandatoryClusteringEnabled: boolean,
    isRefocusToCurrentLocationAfterDirections: boolean
  ): void;
  getGeographicMobile(): MobileUIConfig;

  setGeographicWeb(
    polylineUnitOfMeasurement?: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers',
    polylineRoundingPrecision?: 'ones' | 'tenths' | 'hundredths' | 'thousandths'
  ): void;
  getGeographicWeb(): WebUIConfig;

  getAggregate(aggrIndex: number): AggregateConfig;
  setAggregate(aggrIndex: number, aggregate: AggregateConfig): void;

  getFunction(functionName: string): FunctionDefinition;
  setFunction(functionName: string, func: FunctionDefinition): void;

  getCustomPage(pageName: string): PagesConfig[keyof PagesConfig];
  setCustomPage(pageName: string, page: PagesConfig[keyof PagesConfig]): void;

  getAggregateProperties(aggrIndex: number): PropertyCollection;
  setAggregateProperties(aggrIndex: number, aggregateProperties: PropertyCollection): void;

  getDerivedPropertyMapping(aggrIndex: number): NodeMapDefinition | undefined;
  setDerivedPropertyMapping(aggrIndex: number, derivedPropertyMapping: NodeMapDefinition): void;

  getEvent(aggrIndex: number, eventName: string): EventDefinition | undefined;
  setEvent(aggrIndex: number, eventName: string, event: EventDefinition): void;

  getEvents(aggrIndex: number): Events | undefined;
  setEvents(aggrIndex: number, events: Events): void;

  getEventVersions(aggrIndex: number, eventName: string): EventVersion[] | undefined;
  setEventVersions(aggrIndex: number, eventName: string, versions: EventVersion[]): void;

  getEventVersion(
    aggrIndex: number,
    eventName: string,
    versionIndex: number
  ): EventVersion | undefined;
  setEventVersion(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    version: EventVersion
  ): void;

  setEventInternalDescription(aggrIndex: number, eventName: string, comment: string): void;

  setCommandInternalDescription(aggrIndex: number, eventName: string, comment: string): void;

  setFunctionInternalDescription(name: string, comment: string): void;

  getEventSchema(
    aggrIndex: number,
    eventName: string,
    versionIndex?: number
  ): JSONSchema6 | undefined;
  setEventSchema(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    eventSchema: JSONSchema6
  ): void;

  getAggregateMap(
    aggrIndex: number,
    eventName: string,
    versionIndex: number
  ): NodeMapDefinition | undefined;
  setAggregateMap(
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    aggregateMap: NodeMapDefinition
  ): void;
  setFunctionMap(name: string, versionIndex: number, aggregateMap: NodeMapDefinition): void;

  getCommands(aggrIndex: number): Commands | undefined;
  setCommands(aggrIndex: number, commands: Commands): void;

  getCommand(aggrIndex: number, commandName: string): CommandDefinition | undefined;
  setCommand(aggrIndex: number, commandName: string, commands: CommandDefinition): void;

  getCommandVersions(
    aggrIndex: number,
    commandName: string
  ): CommandVersionDefinition[] | undefined;
  setCommandVersions(
    aggrIndex: number,
    commandName: string,
    versions: CommandVersionDefinition[]
  ): void;

  getCommandVersion(
    aggrIndex: number,
    commandName: string,
    versionIndex: number
  ): CommandVersionDefinition | undefined;
  setCommandVersion(
    aggrIndex: number,
    commandName: string,
    versionIndex: number,
    version: CommandVersionDefinition
  ): void;

  getUICustomizations(UIConfigType: UIConfigType): AggregateUICustomizations | undefined;

  setUICustomizations(
    UIConfigType: UIConfigType,
    aggrUICustomizations: AggregateUICustomizations
  ): void;

  getUICustomization(
    UIConfigType: UIConfigType,
    aggrUIName: string
  ): MobileAggrUICustomization | WebAggrUICustomization | undefined;

  setUICustomization(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    aggrUICustomization: MobileAggrUICustomization | WebAggrUICustomization
  ): void;

  getFabActions(UIConfigType: UIConfigType): FabActions;
  setFabActions(UIConfigType: UIConfigType, fabActions: FabActions): void;

  getImportActions(UIConfigType: UIConfigType): FabActions;
  setImportActions(UIConfigType: UIConfigType, fabActions: FabActions): void;

  getActionButtons(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType
  ): ActionButtons | undefined;
  setActionButtons(
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    fabActions: ActionButtons
  ): void;

  getCardDefinition(UIConfigType: UIConfigType, aggrName: string): CardDefinition | undefined;
  setCardDefinition(
    UIConfigType: UIConfigType,
    aggrName: string,
    cardDefinition: CardDefinition
  ): void;

  configName: string;
  setConfigName(name: string): void;
}

export const ConfigContext = createContext<ContextProps>({} as ContextProps);

export const GlobalContextProvider: React.FC<Props> = ({ children }) => {
  const [config, setConfig] = useState<Gen5Config>(configExample);
  const [configName, setConfigName] = useState('example.json');

  useEffect(() => {
    const configString = window.localStorage.getItem('config');
    if (configString) {
      setConfig(JSON.parse(configString));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('config', JSON.stringify(config));
  }, [config]);

  const getConfig = () => cloneDeep(config);

  const setLegalDisclaimer = (disclaimerTxt: string) => {
    const configCopy = getConfig();
    if (disclaimerTxt.trim().length) {
      setConfig({ ...configCopy, legalDisclaimer: disclaimerTxt });
    } else {
      delete configCopy.legalDisclaimer;
      setConfig({ ...configCopy });
    }
  };

  const getLegalDisclaimer = () => getConfig().legalDisclaimer as string;

  const getNearMaps = () => getConfig().webUIConfig?.integrations?.nearMaps as boolean;
  const getMapServices = () => getConfig().webUIConfig?.integrations?.mapServices as boolean;
  const setMapOptions = (options: {
    nearMaps?: boolean,
    mapServices?: boolean
  }) => {
    const configCopy = getConfig();

    // Update integrations flags.
    configCopy.webUIConfig.integrations = configCopy.webUIConfig.integrations ?? {};
    const currentIntegrations = {...configCopy.webUIConfig.integrations};
    configCopy.webUIConfig.integrations.nearMaps = options.nearMaps ?? currentIntegrations.nearMaps;
    configCopy.webUIConfig.integrations.mapServices = options.mapServices ?? currentIntegrations.mapServices;

    // Update Map Service aggregate and related configurations.
    if (options.mapServices && !currentIntegrations.mapServices) {
      generateMapServiceAggregateData(configCopy);
    }
    else if (!options.mapServices && currentIntegrations.mapServices) {
      removeMapServiceAggregateData(configCopy);
    }

    setConfig(configCopy);
  };

  const setGeographicMobile = (
    isMandatoryClusteringEnabled: boolean,
    isRefocusToCurrentLocationAfterDirections: boolean
  ) => {
    const configCopy = getConfig();
    if (!configCopy.mobileUIConfig.mapSettings)
      configCopy.mobileUIConfig.mapSettings = { isMandatoryClusteringEnabled: false };

    configCopy.mobileUIConfig.mapSettings.isMandatoryClusteringEnabled = isMandatoryClusteringEnabled;
    if (configCopy.mobileUIConfig.enabledFeatures)
      configCopy.mobileUIConfig.enabledFeatures.refocusToCurrentLocationAfterDirections = isRefocusToCurrentLocationAfterDirections;
    else
      configCopy.mobileUIConfig.enabledFeatures = {
        refocusToCurrentLocationAfterDirections: isRefocusToCurrentLocationAfterDirections,
      };

    setConfig(configCopy);
  };

  //Called webUIConfig mapSettings, name not specified
  const setGeographicWeb = (
    polylineUnitOfMeasurement?: 'feet' | 'yards' | 'miles' | 'meters' | 'kilometers',
    polylineRoundingPrecision?: 'ones' | 'tenths' | 'hundredths' | 'thousandths'
  ) => {
    const configCopy = getConfig();
    configCopy.webUIConfig.geographic = {
      polylineUnitOfMeasurement: 'feet',
      polylineRoundingPrecision: 'ones',
    };
    if (polylineUnitOfMeasurement) {
      configCopy.webUIConfig.geographic.polylineUnitOfMeasurement = polylineUnitOfMeasurement;
    }
    if (polylineRoundingPrecision) {
      configCopy.webUIConfig.geographic.polylineRoundingPrecision = polylineRoundingPrecision;
    }
    setConfig(configCopy);
  };

  const getGeographicMobile = () => getConfig().mobileUIConfig as MobileUIConfig;
  const getGeographicWeb = () => getConfig().webUIConfig as WebUIConfig;

  const getAggregates = () => cloneDeep(config.aggregates);
  const setAggregates = (aggregates: AggregateConfig[]) =>
    setConfig({ ...getConfig(), aggregates });

  const getFunctions = () => cloneDeep(config.functions ?? {});
  const setFunctions = (funcs: Record<string, FunctionDefinition>) =>
    setConfig({ ...getConfig(), functions: funcs });

  const getCustomPages = () => cloneDeep(config.pagesConfig ?? {});
  const setCustomPages = (pages: PagesConfig) =>
    setConfig({ ...getConfig(), pagesConfig: pages });

  const getAggregate = (aggrIndex: number) => cloneDeep(config.aggregates[aggrIndex]);
  const setAggregate = (aggrIndex: number, aggregate: AggregateConfig) =>
    setAggregates(getAggregates().map((item, index) => (index === aggrIndex ? aggregate : item)));

  const getFunction = (functionName: string) =>
    cloneDeep(config.functions ? config.functions[functionName] : ({} as FunctionDefinition));
  const setFunction = (functionName: string, func: FunctionDefinition) => {
    const f = getFunctions();
    f[functionName] = func;
    setFunctions(f);
  };

  const getCustomPage = (pageName: string) =>
    cloneDeep(config.pagesConfig?.[pageName] ?? ({} as PagesConfig[keyof PagesConfig]));
  const setCustomPage = (pageName: string, page: PagesConfig[keyof PagesConfig]) => {
    const pages = getCustomPages();
    pages[pageName] = page;
    setCustomPages(pages);
  };

  const getAggregateProperties = (aggrIndex: number) =>
    cloneDeep(config.aggregates[aggrIndex].properties);
  const setAggregateProperties = (aggrIndex: number, aggregateProperties: PropertyCollection) => {
    const aggregate = getAggregate(aggrIndex);
    aggregate.properties = aggregateProperties;
    setAggregate(aggrIndex, aggregate);
  };

  const getDerivedPropertyMapping = (aggrIndex: number) =>
    cloneDeep(config.aggregates?.[aggrIndex]?.derivedPropertyMapping);
  const setDerivedPropertyMapping = (
    aggrIndex: number,
    derivedPropertyMapping: NodeMapDefinition
  ) =>
    setAggregate(aggrIndex, {
      ...getAggregate(aggrIndex),
      ...{ derivedPropertyMapping },
    });

  const getEvents = (aggrIndex: number) => cloneDeep(config.aggregates[aggrIndex]?.events);
  const setEvents = (aggrIndex: number, events: Events) => {
    const aggregate = getAggregate(aggrIndex);
    aggregate.events = events;
    setAggregate(aggrIndex, aggregate);
  };

  const getEvent = (aggrIndex: number, eventName: string) =>
    cloneDeep(config.aggregates[aggrIndex]?.events?.[eventName]);
  const setEvent = (aggrIndex: number, eventName: string, event: EventDefinition) =>
    setEvents(aggrIndex, { ...getEvents(aggrIndex), [eventName]: event });

  const getEventVersions = (aggrIndex: number, eventName: string) =>
    cloneDeep(config.aggregates[aggrIndex]?.events?.[eventName]?.versions);

  const setEventVersions = (aggrIndex: number, eventName: string, versions: EventVersion[]) => {
    const event = getConfig().aggregates[aggrIndex].events?.[eventName];
    if (event) {
      event.versions = versions;
      setEvent(aggrIndex, eventName, event);
    }
  };

  const getEventVersion = (aggrIndex: number, eventName: string, versionIndex: number) =>
    cloneDeep(config.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]);

  const setEventVersion = (
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    version: EventVersion
  ) => {
    const versions = getConfig().aggregates[aggrIndex].events?.[eventName]?.versions;
    if (versions) {
      versions[versionIndex] = version;
      setEventVersions(aggrIndex, eventName, versions);
    }
  };

  const getEventSchema = (aggrIndex: number, eventName: string, versionIndex?: number) => {
    if (versionIndex === 0 || versionIndex) {
      return cloneDeep(
        config.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]?.eventSchema
      );
    } else {
      const eventVersions = config.aggregates[aggrIndex]?.events?.[eventName]?.versions;
      if (eventVersions) {
        const lastVersionIndex = eventVersions.length - 1;
        return cloneDeep(
          config.aggregates[aggrIndex]?.events?.[eventName]?.versions[lastVersionIndex]?.eventSchema
        );
      }
    }
  };

  const setEventInternalDescription = (aggrIndex: number, eventName: string, comment: string) => {
    const event = getConfig().aggregates[aggrIndex].events?.[eventName];
    if (event) {
      event.$internalDescription = comment;
      setEvent(aggrIndex, eventName, event);
    }
  };

  const setCommandInternalDescription = (
    aggrIndex: number,
    commandName: string,
    comment: string
  ) => {
    const command = getConfig().aggregates[aggrIndex].commands?.[commandName];
    if (command) {
      command.$internalDescription = comment;
      setCommand(aggrIndex, commandName, command);
    }
  };

  const setFunctionInternalDescription = (name: string, comment: string) => {
    const func = getFunction(name);
    if (func) {
      func.$internalDescription = comment;
      setFunction(name, func);
    }
  };
  const setEventSchema = (
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    eventSchema: JSONSchema6
  ) => {
    const eventVersion = getEventVersion(aggrIndex, eventName, versionIndex);
    if (eventVersion)
      setEventVersion(aggrIndex, eventName, versionIndex, {
        ...eventVersion,
        ...{ eventSchema },
      });
  };

  const getAggregateMap = (aggrIndex: number, eventName: string, versionIndex: number) =>
    cloneDeep(
      config.aggregates[aggrIndex]?.events?.[eventName]?.versions[versionIndex]?.aggregateMap
    );

  const setAggregateMap = (
    aggrIndex: number,
    eventName: string,
    versionIndex: number,
    aggregateMap: NodeMapDefinition
  ) => {
    const eventVersion = getEventVersion(aggrIndex, eventName, versionIndex);
    if (eventVersion)
      setEventVersion(aggrIndex, eventName, versionIndex, {
        ...eventVersion,
        ...{ aggregateMap },
      });
  };

  const setFunctionMap = (name: string, versionIndex: number, aggregateMap: NodeMapDefinition) => {
    const func = getFunction(name);
    const ver = func?.versions[versionIndex];
    if (ver) func.versions[versionIndex].aggregateMap = aggregateMap;
    setFunction(name, func);
  };

  const getCommands = (aggrIndex: number) => cloneDeep(config.aggregates[aggrIndex]?.commands);
  const setCommands = (aggrIndex: number, commands: Commands) =>
    setAggregate(aggrIndex, { ...getAggregate(aggrIndex), commands });

  const getCommand = (aggrIndex: number, commandName: string) =>
    cloneDeep(config.aggregates[aggrIndex]?.commands?.[commandName]);
  const setCommand = (aggrIndex: number, commandName: string, command: CommandDefinition) =>
    setCommands(aggrIndex, { ...getCommands(aggrIndex), [commandName]: command });

  const getCommandVersions = (aggrIndex: number, commandName: string) =>
    cloneDeep(config.aggregates[aggrIndex]?.commands?.[commandName]?.versions);

  const setCommandVersions = (
    aggrIndex: number,
    commandName: string,
    versions: CommandVersionDefinition[]
  ) => {
    const command = getConfig().aggregates[aggrIndex].commands?.[commandName];
    if (command) {
      command.versions = versions;
      setCommand(aggrIndex, commandName, command);
    }
  };

  const getCommandVersion = (aggrIndex: number, commandName: string, versionIndex: number) =>
    cloneDeep(config.aggregates[aggrIndex]?.commands?.[commandName]?.versions[versionIndex]);

  const setCommandVersion = (
    aggrIndex: number,
    commandName: string,
    versionIndex: number,
    version: CommandVersionDefinition
  ) => {
    const versions = getConfig().aggregates[aggrIndex].commands?.[commandName]?.versions;
    if (versions) {
      versions[versionIndex] = version;
      setCommandVersions(aggrIndex, commandName, versions);
    }
  };

  const getUICustomizations = (UIConfigType: UIConfigType) =>
    cloneDeep(config[UIConfigType].aggregateUICustomizations);
  const setUICustomizations = (
    UIConfigType: UIConfigType,
    aggrUICustomizations: AggregateUICustomizations
  ) => {
    const config = getConfig();
    if (config[UIConfigType].aggregateUICustomizations)
      (config[UIConfigType]
        .aggregateUICustomizations as AggregateUICustomizations) = aggrUICustomizations;
    setConfig(config);
  };

  const getUICustomization = (UIConfigType: UIConfigType, aggrUIName: string) =>
    cloneDeep(config[UIConfigType].aggregateUICustomizations?.[aggrUIName]);
  const setUICustomization = (
    UIConfigType: UIConfigType,
    aggrUIName: string,
    aggrUICustomization: MobileAggrUICustomization | WebAggrUICustomization
  ) => {
    const config = getConfig();
    const aggregateUICustomizations = config[UIConfigType].aggregateUICustomizations;
    if (aggregateUICustomizations) aggregateUICustomizations[aggrUIName] = aggrUICustomization;
    setConfig(config);
  };

  const getFabActions = (UIConfigType: UIConfigType) =>
    cloneDeep(config[UIConfigType].fabActions) as FabActions;
  const setFabActions = (UIConfigType: UIConfigType, fabActions: FabActions) => {
    const config = getConfig();
    const uiConfigType = config[UIConfigType];
    uiConfigType.fabActions = fabActions;
    setConfig(config);
  };

  const getImportActions = (_UIConfigType?: UIConfigType) =>
    cloneDeep(config.webUIConfig.importActions) as FabActions;
  const setImportActions = (_UIConfigType: UIConfigType, fabActions: FabActions) => {
    const config = getConfig();
    const uiConfigType = config.webUIConfig;
    uiConfigType.importActions = fabActions;
    setConfig(config);
  };

  const getActionButtons = (
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType
  ) => extractActionButtons(getConfig(), UIConfigType, aggrUIName, actionButtonType);

  const setActionButtons = (
    UIConfigType: UIConfigType,
    aggrUIName: string,
    actionButtonType: ActionButtonType,
    actionButton: ActionButtons
  ) => {
    const config = setExtractedActionButtons(
      getConfig(),
      UIConfigType,
      aggrUIName,
      actionButtonType,
      actionButton
    );
    if (config) setConfig(config);
  };

  const getCardDefinition = (UIConfigType: UIConfigType, aggrName: string) =>
    cloneDeep(config[UIConfigType].aggregateUICustomizations?.[aggrName]?.cardDefinition);

  const setCardDefinition = (
    UIConfigType: UIConfigType,
    aggrName: string,
    cardDefinition: CardDefinition
  ) => {
    const configCopy = cloneDeep(config);
    const aggrUICustomizagions = configCopy[UIConfigType].aggregateUICustomizations;
    if (aggrUICustomizagions) {
      aggrUICustomizagions[aggrName].cardDefinition = cardDefinition;
      setConfig(configCopy);
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        getConfig,
        setConfig,
        getAggregates,
        setAggregates,
        getAggregate,
        setAggregate,
        getAggregateProperties,
        setAggregateProperties,
        getDerivedPropertyMapping,
        setDerivedPropertyMapping,
        getEvent,
        setEvent,
        getEvents,
        setEvents,
        getEventVersions,
        setEventVersions,
        getEventVersion,
        setEventVersion,
        getEventSchema,
        setEventSchema,
        getAggregateMap,
        setAggregateMap,
        getCommands,
        setCommands,
        getCommand,
        setCommand,
        getCommandVersions,
        setCommandVersions,
        getCommandVersion,
        setCommandVersion,
        getUICustomizations,
        setUICustomizations,
        getUICustomization,
        setUICustomization,
        getFabActions,
        setFabActions,
        getImportActions,
        setImportActions,
        getActionButtons,
        setActionButtons,
        getCardDefinition,
        setCardDefinition,
        configName,
        setConfigName,
        setEventInternalDescription,
        setCommandInternalDescription,
        setFunctionInternalDescription,
        setLegalDisclaimer,
        getLegalDisclaimer,
        setGeographicMobile,
        getGeographicMobile,
        setMapOptions,
        getNearMaps,
        getMapServices,
        getFunction,
        setFunction,
        getFunctions,
        setFunctions,
        setFunctionMap,
        getCustomPage,
        setCustomPage,
        getCustomPages,
        setCustomPages,
        getGeographicWeb,
        setGeographicWeb,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = (): ContextProps => useContext<ContextProps>(ConfigContext);
