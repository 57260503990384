import { V2FormComponentDef } from '@terragotech/form-renderer';
import { camelCaseToSpaceSeparated } from '../../../../components/FormElements';

export const createFormItem = (
  type: string,
  name: string,
  isImportCommand?: boolean
): V2FormComponentDef => {
  switch (type) {
    case 'textArea':
      return {
        type: 'textArea' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter text',
      };
    case 'textInput':
      return {
        type: 'textInput' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter text',
      };
    case 'computed':
      return {
        type: 'computed' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Pending Calculation...',
      };
    case 'numberInput':
      return {
        type: 'numberInput' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter number',
      };
    case 'date':
      return {
        type: 'date' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter date',
      };
    case 'time':
      return {
        type: 'time' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter time',
      };
    case 'textheader':
      return {
        type: 'textheader' as const,
        label: name,
      };
    case 'radio':
      return {
        type: 'radio' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'placeholder',
        options: {
          type: 'SimpleOption',
          items: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
          ],
        },
      };
    case 'barcode':
      return {
        type: 'barcode' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
      };
    case 'location':
      return {
        type: 'location' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter text',
      };
    case 'polyline':
      return {
        type: 'polyline' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter text',
      };
    case 'select':
      return {
        type: 'select' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'placeholder',
        options: {
          type: 'SimpleOption',
          items: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
          ],
        },
      };
    case 'checkbox':
      return {
        type: 'checkbox' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'placeholder',
        options: {
          type: 'SimpleOption',
          items: [
            { value: 'option1', label: 'Option 1' },
            { value: 'option2', label: 'Option 2' },
          ],
        },
      };
    case 'group':
      return {
        type: 'group' as const,
        label: name,
        repeats: isImportCommand
          ? {
              enabled: true,
              min: 1,
              max: -1,
              addButtonText: 'Add Field',
              deleteConfirmationMessage: 'Are you sure you want to delete this?',
              allowReordering: true,
              allowInitialDataDelete: true,
              allowAdding: true,
            }
          : { min: 1, max: 1 },
        template: {
          components: {},
          order: [],
        },
      };
    case 'imageupload':
    case 'fileupload':
    case 'audioupload':
    case 'videoupload':
      return {
        type: type,
        label: name,
      };
    case 'mapAggregateSelector':
      return {
        type: type,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Choose Item',
      };
    default:
      return {
        type: 'textInput' as const,
        required: false,
        label: name,
        repeats: { min: 1, max: 1 },
        placeholder: 'Enter text',
      };
  }
};

export const generateFormItemName = (type: string, formItemsList: string[]): string => {
  let counter = 1;

  const getFreeName = (): string => {
    const name = `${camelCaseToSpaceSeparated(type)}${counter}`;
    if (formItemsList.includes(name)) {
      counter += 1;
      return getFreeName();
    }
    return name;
  };

  return getFreeName();
};
