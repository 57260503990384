import React, { Fragment } from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import styled from 'styled-components';
import { PortModelMap } from '../../DiagramPorts/buildSchemaPortModelFromJSONSchema';
import { InboundProperty, OutboundProperty } from './PropertyComponents';
import { PropertyCollection } from './PropertyCollection';
import SchemaDefinedPortModel from '../../DiagramPorts/SchemaDefinedPort';

export const BASE_HEIGHT = 24;
export const PropertyListFrame = styled.div`
  color: #000000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  background-color: rgba(200, 30, 30, 0);
`;
export const PropertyFrame = styled.div`
  width: 100%;
  height: ${BASE_HEIGHT}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  color: #fff;
`;
interface NestedInboundPropertyProps {
  portMap: PortModelMap;
  engine: DiagramEngine;
  configMode?: boolean;
  onRemove?: (port: SchemaDefinedPortModel) => void;
  onAdd?: (parentPort: SchemaDefinedPortModel) => void;
  inCollection?: boolean;
  hideDisconnected: boolean;
  readOnly?: boolean;
}
/**
 *  The idea here is to take a portModelMap and render each component as what is essentially a tree view
 * @returns
 */
export const NestedInboundProperty: React.FunctionComponent<NestedInboundPropertyProps> = ({
  engine,
  configMode,
  onAdd,
  onRemove,
  portMap,
  inCollection,
  hideDisconnected,
}) => {
  //We're going to render any ports in our map and then render any children below that
  return (
    <>
      {Object.keys(portMap).map((key) => {
        const portEntry = portMap[key];
        if (portEntry.isArray) {
          return (
            <Fragment key={key}>
              {portEntry.port && (
                <InboundProperty
                  engine={engine}
                  configMode={configMode}
                  port={portEntry.port}
                  type={portEntry.port.getTypeSymbol()}
                  portName={portEntry.port.getLabel()}
                  hideDisconnected={hideDisconnected}
                />
              )}
              <PropertyCollection
                hideLabel={true}
                configMode={configMode}
                label={portEntry.port?.getLabel() || ''}
                onAddClick={() => {
                  onAdd && portEntry.port && onAdd(portEntry.port);
                }}
                collectionLength={portEntry?.childPorts?.length || 0}
                hide={hideDisconnected}
              >
                {(portEntry.childPorts || []).map((childPort, index) => (
                  <NestedInboundProperty
                    key={index}
                    engine={engine}
                    configMode={configMode}
                    onRemove={onRemove}
                    onAdd={onAdd}
                    portMap={childPort}
                    inCollection={true}
                    hideDisconnected={hideDisconnected}
                  />
                ))}
              </PropertyCollection>
            </Fragment>
          );
        } else {
          // Pull type from the port I guess
          return (
            <Fragment key={key}>
              {portEntry.port && (
                <InboundProperty
                  engine={engine}
                  configMode={configMode}
                  port={portEntry.port}
                  type={portEntry.port.getTypeSymbol()}
                  portName={portEntry.port.getLabel()}
                  hideDisconnected={hideDisconnected}
                  /* TODO: Need to figure out how to tell the node what to do when a deep port is removed*/
                  onRemove={
                    (inCollection &&
                      onRemove &&
                      (() => {
                        portEntry.port && onRemove(portEntry.port);
                      })) ||
                    (() => {})
                  }
                />
              )}
              {/*Now put in any child ports*/}
              {portEntry.childPorts &&
                portEntry.childPorts.map((childPort, index) => {
                  return (
                    <NestedInboundProperty
                      key={index}
                      engine={engine}
                      configMode={configMode}
                      onRemove={onRemove}
                      onAdd={onAdd}
                      portMap={childPort}
                      hideDisconnected={hideDisconnected}
                    />
                  );
                })}
            </Fragment>
          );
        }
      })}
    </>
  );
};

/**
 *  The idea here is to take a portModelMap and render each component as what is essentially a tree view
 * @returns
 */
export const NestedOutboundProperty: React.FunctionComponent<NestedInboundPropertyProps> = ({
  engine,
  configMode,
  onRemove,
  onAdd,
  portMap,
  inCollection,
  hideDisconnected,
  readOnly,
}) => {
  //We're going to render any ports in our map and then render any children below that
  return (
    <>
      {Object.keys(portMap).map((key) => {
        const portEntry = portMap[key];
        if (portEntry.isArray) {
          return (
            <Fragment key={key}>
              {portEntry.port && (
                <OutboundProperty
                  engine={engine}
                  configMode={configMode}
                  port={portEntry.port}
                  type={portEntry.port.getTypeSymbol()}
                  portName={portEntry.port.getLabel()}
                  hideDisconnected={hideDisconnected}
                  readOnly={readOnly}
                />
              )}
              {/*Now put in any child ports*/}
              {portEntry.port?.getTypeSymbol() === 'O[]' &&
                portEntry.childPorts &&
                portEntry.childPorts.map((childPort, index) => {
                  return (
                    <NestedOutboundProperty
                      key={index}
                      engine={engine}
                      configMode={configMode}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      portMap={childPort}
                      hideDisconnected={hideDisconnected}
                      readOnly={true}
                    />
                  );
                })}
            </Fragment>
          );
        } else {
          // Pull type from the port I guess
          return (
            <Fragment key={key}>
              {portEntry.port && (
                <OutboundProperty
                  engine={engine}
                  configMode={configMode}
                  port={portEntry.port}
                  type={portEntry.port.getTypeSymbol()}
                  portName={portEntry.port.getLabel()}
                  hideDisconnected={hideDisconnected}
                  /* TODO: Need to figure out how to tell the node what to do when a deep port is removed*/
                  onRemove={
                    (inCollection &&
                      onRemove &&
                      (() => {
                        portEntry.port && onRemove(portEntry.port);
                      })) ||
                    (() => {})
                  }
                  readOnly={readOnly}
                />
              )}
              {/*Now put in any child ports*/}
              {portEntry.childPorts &&
                portEntry.childPorts.map((childPort, index) => {
                  return (
                    <NestedOutboundProperty
                      key={index}
                      engine={engine}
                      configMode={configMode}
                      onAdd={onAdd}
                      onRemove={onRemove}
                      portMap={childPort}
                      hideDisconnected={hideDisconnected}
                      readOnly={readOnly}
                    />
                  );
                })}
            </Fragment>
          );
        }
      })}
    </>
  );
};
