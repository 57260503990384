import * as React from 'react';
import { styled, Paper, Button, FormControlLabel, Switch, FormHelperText } from '@material-ui/core';
import { useCallback, useContext, useState } from 'react';
import { ConfigContext } from '../../context/ConfigContext';
import { useParams } from 'react-router-dom';
import { WebAggrUICustomization, MobileAggrUICustomization } from '../../utils/types';
import { successMsg } from '../SnackbarUtilsConfigurator';
import { RadioInput, SelectInput } from '../FormElements';
import { getCustomPageList } from 'utils/helpers';

export interface VisibilityDialogProps {
  type: 'web' | 'mobile';
}

const GeneralDialog: React.FunctionComponent<VisibilityDialogProps> = (props) => {
  const { type } = props;
  const { getUICustomization, setUICustomization, config } = useContext(ConfigContext);
  const { aggrUICustomization } = useParams() as { aggrUICustomization: string };
  const currentCustomizations =
    (type === 'web'
      ? getUICustomization('webUIConfig', aggrUICustomization)
      : getUICustomization('mobileUIConfig', aggrUICustomization)) || null;

  const [showOnMap, setShowOnMap] = useState(
    currentCustomizations === null ? true : !currentCustomizations.hiddenFromMapView
  );
  const [showOnList, setShowOnList] = useState(
    currentCustomizations === null
      ? true
      : !(currentCustomizations as { hiddenFromListView: boolean }).hiddenFromListView
  );
  const [showOnTable, setShowOnTable] = useState(
    currentCustomizations === null
      ? true
      : !(currentCustomizations as { hiddenFromTableView: boolean }).hiddenFromTableView
  );
  const [attributesType, setAttributesType] = useState(
    !!currentCustomizations?.detailPage ? 'customPage' : 'attributes'
  );
  const [pageName, setPageName] = useState(currentCustomizations?.detailPage ?? '');
  const handleSave = useCallback(() => {
    if (type === 'web') {
      setUICustomization('webUIConfig', aggrUICustomization, {
        ...(currentCustomizations as WebAggrUICustomization),
        hiddenFromMapView: !showOnMap,
        hiddenFromTableView: !showOnTable,
        detailPage: pageName,
      });
    } else {
      setUICustomization('mobileUIConfig', aggrUICustomization, {
        ...(currentCustomizations as MobileAggrUICustomization),
        hiddenFromMapView: !showOnMap,
        hiddenFromListView: !showOnList,
        detailPage: pageName,
      });
    }
    successMsg('Visibility settings have been saved.');
  }, [
    aggrUICustomization,
    currentCustomizations,
    setUICustomization,
    showOnList,
    showOnMap,
    showOnTable,
    type,
    pageName,
  ]);
  return (
    <Container>
      <HeaderContainer>
        <Title>General</Title>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          style={{ margin: '10px 0' }}
        >
          Save
        </Button>
      </HeaderContainer>
      <BodyContainer>
        <Title>Visibility</Title>
        <RadioContainer>
          <FormControlLabel
            control={
              <Switch
                checked={showOnMap}
                onChange={() => {
                  setShowOnMap((cur) => !cur);
                }}
                color="primary"
              />
            }
            label="Show on map"
          />
          <FormHelperText>
            Disabling this option will hide this aggregate type from the map view on the {type}{' '}
            device for all users
          </FormHelperText>
        </RadioContainer>
        {type === 'mobile' && (
          <RadioContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={showOnList}
                  onChange={() => {
                    setShowOnList((cur) => !cur);
                  }}
                  color="primary"
                />
              }
              label="Show on list"
            />
            <FormHelperText>
              Disabling this option will hide this aggregate type from the list view on the {type}{' '}
              device for all users
            </FormHelperText>
          </RadioContainer>
        )}
        {type === 'web' && (
          <RadioContainer>
            <FormControlLabel
              control={
                <Switch
                  checked={showOnTable}
                  onChange={() => {
                    setShowOnTable((cur) => !cur);
                  }}
                  color="primary"
                />
              }
              label="Show on table"
            />
            <FormHelperText>
              Disabling this option will hide this aggregate type from the table view on the {type}{' '}
              device for all users
            </FormHelperText>
          </RadioContainer>
        )}

        <Title style={{ marginTop: 20 }}>Detail Page</Title>
        <RadioInput
          title=""
          value={attributesType}
          onChange={(value) => setAttributesType(value)}
          options={[
            { value: 'attributes', label: 'Attributes' },
            { value: 'customPage', label: 'Custom Page' },
          ]}
        />

        {attributesType === 'customPage' && (
          <SelectInput
            title="Custom Page"
            options={getCustomPageList(config, aggrUICustomization)}
            onChange={(value) => setPageName(value)}
            value={pageName}
          />
        )}
      </BodyContainer>
    </Container>
  );
};
export default GeneralDialog;
const Title = styled('p')({ margin: 'auto 0px' });
const Container = styled('div')({
  width: '100%',
  flex: 1,
});

const HeaderContainer = styled(Paper)({
  padding: '0px 20px',
  margin: 5,
  flex: 1,
  overflowX: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  verticalAlign: 'middle',
});
const BodyContainer = styled(Paper)({
  padding: '12px 24px',
  margin: 5,
  flex: 1,
  overflowX: 'auto',
  display: 'flex',
  flexDirection: 'column',
});
const RadioContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
