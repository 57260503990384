import { JSONSchema6 } from 'json-schema';
import { SchemaDefinition, SchemaLookup } from '@terragotech/gen5-datamapping-lib';
import { useConfig } from '../context/ConfigContext';
import { useMemo } from 'react';
import { propertiesToSchema } from '../pages/aggregates/utils/PropertiesToSchemaConverter';
import { generateEventSchema } from './jsonPartsGenerators';

export interface LocalSchemaDefinition {
  [index: string]: {
    schema: JSONSchema6;
    schemaLabel: string;
  };
}
interface UseSchemaLookupProps {
  localSchemas?: LocalSchemaDefinition;
  currentAggregateType?: string;
}
/**
 * This takes in specific schemas, plus looks at the existing config to grab any other schemas available in the current configuration
 * @param localSchemas array of scenario specific schemas
 * @param currentAggregateType The currently selected aggreagte type, used to decide which schemas to make available
 * @returns the schema lookup implementation used by the mapper
 */
export const useSchemaLookup = (props: UseSchemaLookupProps): SchemaLookup => {
  const { getAggregates, getConfig } = useConfig();
  const clonedConfig = getConfig();
  const lookup: SchemaLookup = useMemo(() => {
    const schemas: LocalSchemaDefinition = { ...props.localSchemas };
    return {
      getAggregates: () => {
        return getAggregates().map((agg) => agg.typeName);
      },
      getSchema: (schemaId) => {
        if (schemas[schemaId]) {
          return schemas[schemaId].schema;
        } else {
          const parts = schemaId && schemaId.split('::');
          if (parts && parts.length >= 1) {
            // Form: <Aggregate>::<Event>::<Event version>
            const [aggregateKey, eventKey, eventVersionNumber] = parts;
            const aggregate = getAggregates().find((agg) => agg.typeName === aggregateKey);
            if (aggregate) {
              const event = aggregate.events?.[eventKey];
              if (event) {
                const eventVersion = event.versions.find(
                  (eventVer) => eventVer.versionNumber === Number.parseInt(eventVersionNumber)
                );
                //TODO: Default to the latest version if none provided
                return eventVersion ? eventVersion.eventSchema : {};
              } else if (eventKey === 'DeleteEvent') {
                return generateEventSchema('DeleteEvent', {});
              } else {
                return propertiesToSchema(aggregate.properties);
              }
            }
          }
        }
        return {};
      },
      getSchemas: (options) => {
        if (options?.aggregateType) {
          const aggSchemas: LocalSchemaDefinition = { ...props.localSchemas };
          const aggregate = getAggregates().find((agg) => agg.typeName === options.aggregateType);
          // once an aggregate is found, we need to add schemas for all available events of that aggregate
          const events = aggregate?.events;
          if (aggregate && events) {
            Object.keys(events).forEach((key) => {
              //add event to schema
              const event = events[key];
              event.versions.forEach((version) => {
                aggSchemas[`${aggregate.typeName}::${key}::${version.versionNumber}`] = {
                  schema: version.eventSchema,
                  schemaLabel: `${key}: V${version.versionNumber}`,
                };
              });
              //Add static DeleteEvent
              aggSchemas[`${aggregate.typeName}::DeleteEvent::1`] = {
                schema: generateEventSchema('DeleteEvent', {}),
                schemaLabel: `DeleteEvent: V1`,
              };
            });
          }
          return Object.keys(aggSchemas).map((key) => ({
            schemaId: key,
            schemaLabel: aggSchemas[key].schemaLabel,
          }));
        }
        const outputSchemas = Object.keys(schemas).map((key) => ({
          schemaId: key,
          schemaLabel: schemas[key].schemaLabel,
        }));
        //Also return schema for each aggregate
        getAggregates().forEach((agg) => {
          outputSchemas.push({
            schemaId: agg.typeName,
            schemaLabel: agg.typeName,
          });
        });

        return outputSchemas;
      },
      getFunctionInput: (id) => {
        const parts = id && id.split('::');
        if (parts && parts.length >= 1) {
          // Form: <functionName>::<version>
          const [functionName, version] = parts;
          const config = clonedConfig.functions;
          const func = config ? config[functionName] : undefined;
          if (func) {
            const funcVersion = func.versions.find(
              (ver) => ver.versionNumber === Number.parseInt(version)
            );
            if (funcVersion) {
              return funcVersion.input;
            }

            let v: number;
            func.versions.forEach((ver) => {
              v = Math.max(ver.versionNumber, v);
            });
            return func.versions.find((ver) => ver.versionNumber === v)?.input || {};
          }
        }
        return {};
      },
      getFunctionOutput: (id) => {
        const parts = id && id.split('::');
        if (parts && parts.length >= 1) {
          // Form: <functionName>::<version>
          const [functionName, version] = parts;
          const config = clonedConfig.functions;
          const func = config ? config[functionName] : undefined;
          if (func) {
            const funcVersion = func.versions.find(
              (ver) => ver.versionNumber === Number.parseInt(version)
            );
            if (funcVersion) {
              return funcVersion.output;
            }

            let v: number;
            func.versions.forEach((ver) => {
              v = Math.max(ver.versionNumber, v);
            });
            return func.versions.find((ver) => ver.versionNumber === v)?.output || {};
          }
        }
        return {};
      },
      getFunctions: () => {
        const conf = clonedConfig;
        const result: SchemaDefinition[] = [];
        if (conf.functions) {
          Object.keys(conf.functions).forEach((key) => {
            if (conf.functions) {
              conf.functions[key].versions.forEach((item) => {
                result.push({
                  schemaId: key + '::' + item.versionNumber,
                  schemaLabel: key + ' v' + item.versionNumber,
                });
              });
            }
          });
        }
        return result;
      },
    };
  }, [getAggregates, props.localSchemas, getConfig]);
  return lookup;
};
